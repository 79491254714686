/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { Flex, Span } from '../../lib/components'
import { getDormantCountDownString, getFormattedDate } from '../../lib/utils/helpers'
import { accountStatusMap, InvestorKYCProps, KycStatus } from '../../lib/types'

const InvestorKYC = ({ kycData, investorState, investmentDetail }: InvestorKYCProps) => {
  return (
    <>
      <Flex variant='row'>
        <Span classes={['']} variant={'bold'} text='KYC Status:' />
        <Span classes={['ml-8', '']} variant={'thin'} text={(kycData && kycData!.status) ?? ''} />
      </Flex>
      <Flex variant='row' classes={['mt-8']}>
        <Span classes={['']} variant={'bold'} text='Account Status:' />
        <Span
          classes={['ml-8', '']}
          variant={'thin'}
          text={(investorState && investorState?.investor?.accountStatus) ?? ''}
        />
      </Flex>
      {kycData &&
        kycData!.status === KycStatus.APPROVED &&
        investorState &&
        investorState?.investor?.accountStatus !== accountStatusMap.DORMANT &&
        !!kycData!.kyc_approval_date && (
          <>
            <Flex variant={'row'} classes={['mt-8']}>
              <Span classes={['']} variant={'bold'} text='KYC Approval Date:' />
              <Span
                classes={['ml-8', '']}
                variant={'thin'}
                text={
                  (kycData &&
                    kycData!.kyc_approval_date &&
                    getFormattedDate(new Date(kycData!.kyc_approval_date), 'D MMM YYYY')) ??
                  ''
                }
              />
            </Flex>
            <Flex variant={'row'} classes={['mt-8']}>
              <Span classes={['']} variant={'bold'} text='Time left to become dormant:' />
              {Number(investmentDetail && investmentDetail?.totalInvestment) > 0 ? (
                <Span
                  classes={['ml-8', '']}
                  variant={'thin'}
                  text={'N.A. (Investor has some investments)'}
                />
              ) : (
                <Span
                  classes={['ml-8', '']}
                  variant={'thin'}
                  text={
                    (kycData &&
                      kycData!.kyc_approval_date &&
                      `${getDormantCountDownString(
                        kycData!.kyc_approval_date,
                      )} (Investor has no investments)`) ??
                    ''
                  }
                />
              )}
            </Flex>
          </>
        )}
    </>
  )
}

export { InvestorKYC }
