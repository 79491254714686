import { accountStatusMap, JobResponse, Jobs, NewOpportunity, OrderCardProps } from '../types'
import dayjs from 'dayjs'
import { tableStatusMappings } from './message'

export const concatClassName = (classNames?: string[]) => {
  let concatClass = ''
  classNames &&
    classNames.map((className) => {
      concatClass = concatClass.concat(className).concat(' ')
    })
  return concatClass
}

export const stringToArray = (string: string) => {
  if (!string) return
  return string.split(';')
}

export const arrayToString = (array: string[]) => {
  if (!array) return
  return array.join(';')
}

export const createSlug = (slug: string) => slug.toLowerCase().replace(/[^\w-]+/g, '-')

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
export const numberRegex = /^[1-9]\d*(\.\d+)?$/
export const calculateRemainingDays = (startDate: Date, endDate: Date): number => {
  // const startDate = Date.now()
  if (startDate.getTime() < endDate.getTime())
    return Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24))
  else return 0
}

export const convertToNumber = (input: string): number => {
  let convertedToNumber = ''
  for (let i = 0; i < input.length; i++) {
    const hasNumber = /\d/
    if (hasNumber.test(input[i]) || input[i] === '.') {
      convertedToNumber += input[i]
    }
  }
  try {
    const num = parseFloat(convertedToNumber)
    return isNaN(num) ? 0 : num
  } catch (err) {
    return 0
  }
}

export const convertToFloat = (value: string): number => {
  try {
    const num = parseFloat(value)
    return isNaN(num) ? 0 : num
  } catch (err) {
    return 0
  }
}

export const calculateCommittedPercentage = (
  committedAmount: string,
  raiseGoal: string,
): string => {
  const amount = convertToFloat(committedAmount)
  const goal = convertToFloat(raiseGoal)
  if (goal > 0) {
    const remainingGoal = (amount * 100) / goal
    return remainingGoal.toFixed(2).toString().concat('%')
  }
  return '0.00%'
}

export const calculatePercentage = (minValue: string, maxValue: string): number => {
  const min = convertToFloat(minValue)
  const max = convertToFloat(maxValue)
  if (max > 0) {
    const percentage = (min * 100) / max
    return percentage
  }
  return -1
}

export const isValidFileSize = (file: File, type: 'image' | 'doc' | 'video') => {
  /* 1024 = 1MB */
  const size = Math.round(file.size / 1024)
  if (type === 'doc' && size <= 1 * 1024 * 10) {
    return true
  } else if (type === 'image' && size <= 1 * 1024 * 10) {
    return true
  } else if (type === 'video' && size <= 1 * 1024 * 10) {
    return true
  } else {
    return false
  }
}

export const isValidImageType = (file: File) => {
  const extension = file.name.substr(file.name.lastIndexOf('.'))

  /* define allowed file types */
  const allowedExtensionsRegx = /(\.jpg|\.jpeg)$/i

  /* testing extension with regular expression */
  const isAllowed = allowedExtensionsRegx.test(extension)

  if (isAllowed) {
    return true
  } else {
    return false
  }
}

export const isValidDocType = (file: File) => {
  const extension = file.name.substr(file.name.lastIndexOf('.'))

  /* define allowed file types */
  const allowedExtensionsRegx = /(\.docx|\.pdf)$/i

  /* testing extension with regular expression */
  const isAllowed = allowedExtensionsRegx.test(extension)

  if (isAllowed) {
    return true
  } else {
    return false
  }
}

export const isValidVideoType = (file: File) => {
  const extension = file.name.substr(file.name.lastIndexOf('.'))

  /* define allowed file types */
  const allowedExtensionsRegx = /(\.mp4)$/i

  /* testing extension with regular expression */
  const isAllowed = allowedExtensionsRegx.test(extension)

  if (isAllowed) {
    return true
  } else {
    return false
  }
}

export const displayPercentage = (value: string): string => {
  return `${convertToFloat(value).toFixed(2).toString()}%`
}

export const truncateNumber = (num: number, places = 2) => {
  return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places)
}

export const displayDollar = (
  value: string | undefined,
  currencyCode: string | undefined,
  maximumFractionDigits = 2,
  forceStandardFormat = false,
  forceFractionDigits = false,
) => {
  const oneBillion = 1000000000
  const oneMillion = 1000000
  const oneThousand = 1000
  const dollar = value && parseFloat(value) / 100
  let output = 0

  if (Number.isInteger(Number(value)) && !forceFractionDigits) maximumFractionDigits = 0

  if (dollar && dollar >= oneBillion && !forceStandardFormat) {
    output = truncateNumber(dollar / oneBillion)
    return `${output.toLocaleString('en-US', {
      style: 'currency',
      currency: currencyCode || 'USD',
      maximumFractionDigits: maximumFractionDigits,
    })}b`
  } else if (dollar && dollar >= oneMillion && !forceStandardFormat) {
    output = truncateNumber(dollar / oneMillion)
    return `${output.toLocaleString('en-US', {
      style: 'currency',
      currency: currencyCode || 'USD',
      maximumFractionDigits: maximumFractionDigits,
    })}m`
  } else if (dollar && dollar >= oneThousand && !forceStandardFormat) {
    output = truncateNumber(dollar / oneThousand)
    return `${output.toLocaleString('en-US', {
      style: 'currency',
      currency: currencyCode || 'USD',
      maximumFractionDigits: maximumFractionDigits,
    })}k`
  }
  return dollar
    ? `${dollar.toLocaleString('en-US', {
        style: 'currency',
        currency: currencyCode || 'USD',
        maximumFractionDigits: maximumFractionDigits,
      })}`
    : '$0'
}

export const displayCurrency = (dollar: string, currencyCode = 'USD'): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: 2,
    notation: 'compact',
  }).format(convertToFloat(dollar))
}

export const getFileKeyFromSignedUrl = (signedUrl: string): string => {
  if (signedUrl.startsWith('http')) {
    const regexToExtractKey = /^.*\/([^/]+?)\?.*$/
    const match = signedUrl.match(regexToExtractKey)
    return match && match[1] ? match[1] : signedUrl
    // return signedUrl.split('/')[3].split('?')[0]
  }
  return signedUrl
}

export const sanitizeSignedUrlsForObject = (keyNames: string[], data: object): object => {
  const updated: any = data
  Object.keys(data).map((key) => {
    if (keyNames.indexOf(key) !== -1) {
      updated[key] = getFileKeyFromSignedUrl(updated[key])
    }
  })
  return updated
}

export const removeEmptyValueFromObject = (source: any) => {
  Object.keys(source).forEach((key) => {
    if (!source[key] || source[key] === '') delete source[key]
  })
  return source
}

export const formatPercentage = (percentage: any) => {
  try {
    const fixed = parseFloat(percentage).toFixed(2)
    return `${fixed}%`
  } catch (err) {
    return '0%'
  }
}

export const trimText = (content: string, charCount = 100) => {
  if (!content) return ''
  return content.length < charCount ? content : content.substring(0, charCount).concat('...')
}

export const formatStatus = (status: string) => {
  if (!status) return ''
  const lower = status.toLowerCase()
  const capitalize = lower[0].toUpperCase() + lower.slice(1)
  return capitalize.replace(/_/g, ' ').replace(/-/g, ' ')
}

export const filterJobFromJobResp = (respose: JobResponse[]): Jobs[] => {
  const nested = respose.map((resp) => {
    return resp.payload.fileIds.map((fileId, index): Jobs => {
      const jobResult =
        resp.result && resp.result.jobResults && resp.result.jobResults.length > 0
          ? resp.result.jobResults[index]
          : undefined

      let status = 'processing'
      if (jobResult && jobResult.status) {
        status = jobResult.status === 'failed' ? jobResult.reason : jobResult.status
      }

      return {
        jobId: resp.jobId,
        createdAt: resp.createdAt,
        fileId: fileId,
        status: status,
        email: jobResult ? jobResult.email : '',
        investmentId: jobResult && jobResult.metadata ? jobResult.metadata.id || '' : '',
      }
    })
  })
  return nested.flat()
}

export const isMultiple = (x: number, y: number) => (x % y === 0 ? true : false)

export const convertNumberWithCommas = (input: string | number): string => {
  const val = typeof input === 'string' ? convertToNumber(input) : input
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const convertDateToDayjs = (date: Date | undefined | null) => {
  return dayjs(date)
}

export const sanitizeOpportunityPayload = (
  payload: NewOpportunity,
  fieldsToRemove: string[],
): NewOpportunity => {
  const sanitizedPayload = { ...payload }

  fieldsToRemove.forEach((field: string) => {
    delete (sanitizedPayload as any)[field]
  })

  // For sanitizing fields like 'Highlights', 'Images', 'Key Factors'
  Object.keys(sanitizedPayload).forEach((key) => {
    if (Array.isArray(sanitizedPayload[key])) {
      sanitizedPayload[key] = (sanitizedPayload[key] as []).filter(
        (item: any) => item !== null && item !== undefined && item !== '',
      )
    }
  })

  // For sanitizing 'Documents' field
  if ('documents' in sanitizedPayload && sanitizedPayload.documents.length > 0) {
    sanitizedPayload.documents = sanitizedPayload.documents.filter(
      (item: any) => item.url.trim() !== '' && item.title.trim() !== '',
    )
  }

  return sanitizedPayload
}

export const getFormattedDate = (date: Date, format: string): any => {
  return dayjs(date).format(format)
}

export const getDormantCountDownString = (approvalDate: string | Date): string => {
  const startDate = new Date(approvalDate)
  const dormantDate = new Date(startDate)
  dormantDate.setMonth(dormantDate.getMonth() + 6)

  const currentDate = new Date()

  return getPeriodDifferenceString(currentDate, dormantDate)
}

export const getPeriodDifferenceString = (start: string | Date, end: string | Date): string => {
  const startDate = new Date(start)
  const targetDate = new Date(end)

  // Calculate the difference in milliseconds
  const diffTime = targetDate.getTime() - startDate.getTime()
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  // If less than 1 day
  if (diffDays < 1) {
    return '< 1 day'
  }

  // If less than 7 days, return days
  if (diffDays <= 7) {
    if (diffDays === 7) {
      return '1+ week left'
    }
    if (diffDays === 1) {
      return '1 day left'
    }
    return `${diffDays}+ days left`
  }

  // Calculate weeks
  const diffWeeks = Math.floor(diffDays / 7)
  if (diffWeeks <= 4) {
    if (diffWeeks === 4) {
      return '4+ weeks left'
    }
    if (diffWeeks === 1) {
      return '1 week left'
    }
    return `${diffWeeks}+ weeks left`
  }

  // Calculate months
  const diffMonths = Math.floor(diffDays / 31)
  if (diffMonths <= 6) {
    if (diffMonths === 6) {
      return '6 months left'
    }
    if (diffMonths === 1) {
      return '1 month left'
    }
    return `${diffMonths}+ months left`
  }

  return '6 months left'
}

export const getTableClassNames = ({
  investor_account_status,
  investment_completed,
  completed_steps,
  payment_copy = '',
  transaction_reference_number = '',
}: Partial<OrderCardProps>) => {
  if (investor_account_status === accountStatusMap.DORMANT) {
    return tableStatusMappings.dormant.class
  }

  if (investment_completed !== undefined && investment_completed === false) {
    if (completed_steps === '2' && !payment_copy && !transaction_reference_number) {
      return tableStatusMappings['incomplete-order-3'].class
    }

    return tableStatusMappings['incomplete-order'].class
  }

  return ''
}

export const getToolTipText = ({
  investor_account_status,
  investment_completed,
  completed_steps,
  payment_copy = '',
  transaction_reference_number = '',
}: Partial<OrderCardProps>) => {
  if (investor_account_status === accountStatusMap.DORMANT) {
    return tableStatusMappings.dormant.tooltipText
  }

  if (investment_completed !== undefined && investment_completed === false) {
    if (completed_steps === '2' && !payment_copy && !transaction_reference_number) {
      return tableStatusMappings['incomplete-order-3'].tooltipText
    }
    return tableStatusMappings['incomplete-order'].tooltipText
  }

  return ''
}
