/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react'
import {
  H2,
  P,
  Button,
  Card,
  Span,
  LeftArrowIcon,
  Flex,
  Line,
  H4,
  ProgressBar,
  InputModal,
  Close,
  Input,
  EditIcon,
  ToolTip,
  H5,
} from '../../lib/components'
import { InvestorKYC } from './investor-kyc'
import { useNavigate } from 'react-router-dom'
import {
  calculatePercentage,
  displayDollar,
  convertNumberWithCommas,
} from '../../lib/utils/helpers'
import {
  AccountStatus,
  accountStatusMap,
  KycStatus,
  ProfileDetailsPresenterProps,
} from '../../lib/types'
import { tableStatusMappings } from '../../lib/utils/message'
import { CheckCircleIcon, PendingIcon, ReportIcon } from '../../lib/components/Icon'

const ProfileDetailsPresenter = ({
  investorState,
  investmentDetail,
  kycData,
  handleInvestmentLimit,
  handleSendVerificationEmailLink,
  handleBlockUnblock,
}: ProfileDetailsPresenterProps) => {
  const navigate = useNavigate()
  const [displayModalInvestment, setDisplayModalInvestment] = useState(false)
  const [investmentLimitData, setInvestmentLimitData] = useState<string>('')
  useEffect(() => {
    setInvestmentLimitData(
      investmentDetail?.investmentLimit
        ? (Number(investmentDetail?.investmentLimit) / 100).toString()
        : '',
    )
  }, [investmentDetail])
  return (
    <>
      {investorState && (
        <>
          <Flex>
            <Button
              classes={['auto-small-btn-width']}
              variant='secondary-btn'
              IconComponent={<LeftArrowIcon />}
              handleClick={() => navigate('/investors')}
            />
            <Span classes={['ml-16', 'font-16']} variant='bold' text={'Back To Investors'} />
          </Flex>
          <Flex variant='space-between'>
            <H2 classes={['mb-48', 'mt-16']} text='Investor Profile' />
          </Flex>
          <Flex variant='row' classes={['mb-24', 'investor-profile-card-container']}>
            <Card classes={['p-40', 'mr-16', 'mb-16']}>
              <P classes={['mb-8']} text='Investment Details' />
              <Flex variant='space-between'>
                <Span variant='bold' text='Investment Limit' />
                <Span
                  variant='thin'
                  text={
                    investmentDetail?.investmentLimit || investmentDetail?.totalInvestment
                      ? `$${convertNumberWithCommas(
                          Number(investmentDetail?.totalInvestment) / 100,
                        )}/$${convertNumberWithCommas(
                          Number(investmentDetail?.investmentLimit) / 100,
                        )}`
                      : '$0/$0'
                  }
                />
              </Flex>
              <ProgressBar
                variant='default-bar'
                maxValue={
                  investmentDetail?.investmentLimit ? investmentDetail.investmentLimit : '100'
                }
                value={investmentDetail?.totalInvestment ? investmentDetail?.totalInvestment : '0'}
              />
              <Span
                variant='thin'
                text={
                  investmentDetail?.totalInvestment &&
                  investmentDetail?.investmentLimit &&
                  calculatePercentage(
                    investmentDetail?.totalInvestment,
                    investmentDetail?.investmentLimit,
                  ) > 90
                    ? 'Your investment limit is soon to be reached.'
                    : ''
                }
              />
            </Card>
            <Card classes={['p-40', 'mr-16', 'mb-16']}>
              <Flex variant='space-between'>
                <P classes={['mb-8']} text='General Details' />
                {investorState &&
                  investorState.investor?.accountStatus === accountStatusMap.DORMANT && (
                    <ToolTip content={tableStatusMappings.dormant.tooltipText}>
                      <span>
                        <PendingIcon classes={['info-icon', 'ml-8']} width='20px' height='24px' />
                      </span>
                    </ToolTip>
                  )}
              </Flex>
              <Flex variant='space-between'>
                <Span variant='bold' text='Name' />
                <Span
                  variant='thin'
                  text={investorState.investor?.name ? investorState.investor.name : ''}
                />
              </Flex>
              <Flex variant='space-between'>
                <Span variant='bold' text='Email' />
                <Span
                  variant='thin'
                  text={investorState.investor?.email ? investorState.investor.email : ''}
                />
              </Flex>
              <Flex variant='space-between'>
                <Span variant='bold' text='Investor ID' />
                <Span
                  variant='thin'
                  text={
                    investorState.investor?.investorReference
                      ? investorState.investor?.investorReference
                      : ''
                  }
                />
              </Flex>
              <Flex variant='space-between'>
                <Span variant='bold' text='Portfolio ID' />
                <Span
                  variant='thin'
                  text={kycData?.investor_portfolio_id ? kycData?.investor_portfolio_id : ''}
                />
              </Flex>
              <Flex variant='space-between'>
                <Span variant='bold' text='Country of residence' />
                <Span
                  variant='thin'
                  text={
                    investorState.investor?.countryOfResidence
                      ? investorState.investor.countryOfResidence
                      : ''
                  }
                />
              </Flex>
              <Flex variant='space-between'>
                <Span variant='bold' text='Mobile phone' />
                <Span
                  variant='thin'
                  text={
                    investorState.investor?.phoneNumber
                      ? investorState.investor.phoneNumber.toString()
                      : ''
                  }
                />
              </Flex>
              {investorState?.investor?.accountStatus !== 'DORMANT' && (
                <Flex variant='flex-end'>
                  <Button
                    classes={['mb-32', 'mt-16', 'auto-btn-width']}
                    variant={
                      investorState.investor.accountStatus === 'ACTIVE'
                        ? 'primary-btn'
                        : 'secondary-btn'
                    }
                    handleClick={() =>
                      handleBlockUnblock(
                        investorState.investor.accountStatus as Exclude<AccountStatus, 'DORMANT'>,
                      )
                    }
                    text={
                      investorState.investor?.accountStatus === 'ACTIVE'
                        ? 'Block Investor'
                        : 'Unblock Investor'
                    }
                  />
                </Flex>
              )}
            </Card>
          </Flex>

          <Flex classes={['upload-quarterly-report-container']}>
            <Card classes={['p-40', 'mb-16']}>
              <P classes={['mb-8']} text='Resend verification link to the Investor Email' />
              <Button
                classes={['auto-btn-width']}
                variant='primary-btn'
                handleClick={handleSendVerificationEmailLink}
                text='Resend verification link'
                isDisabled={investorState?.investor?.isEmailVerified ? true : false}
              />
              {investorState?.investor?.isEmailVerified ? (
                <div className='investment-success-sub-heading mt-16 mr-16'>
                  <p>{'The investor has verified the email.'}</p>
                </div>
              ) : (
                <div className='investment-warning-sub-heading mt-16 mr-16'>
                  <p>
                    {
                      'The investor has not verified the email. Use this button to resend the verification link to the investor email.'
                    }
                  </p>
                </div>
              )}
            </Card>
            <Card classes={['p-40', 'mb-16']}>
              <Flex variant='space-between'>
                <div>
                  <P classes={['mb-8']} text='Investment limit' />
                  {displayModalInvestment ? (
                    <InputModal>
                      <Flex variant='column' classes={['input-modal']}>
                        <Flex variant='space-between'>
                          <H4 text={'Investment Limit'} />
                          <Close height='24px' onClick={() => setDisplayModalInvestment(false)} />
                        </Flex>
                        <Line />
                        <Flex variant='column' classes={['reply-section']}>
                          <Input
                            name='investmentLimit'
                            placeholder='Investment Limit'
                            type='number'
                            required
                            value={investmentLimitData || ''}
                            handleChange={(e) => setInvestmentLimitData(e.target.value)}
                          />
                          <Button
                            variant='primary-btn'
                            text='Save'
                            handleClick={() => {
                              investmentLimitData &&
                                handleInvestmentLimit(Number(investmentLimitData))
                              setDisplayModalInvestment(false)
                            }}
                          />
                        </Flex>
                      </Flex>
                    </InputModal>
                  ) : null}
                  <span>
                    {displayDollar(
                      investmentDetail?.investmentLimit
                        ? investmentDetail.investmentLimit.toString()
                        : '',
                      'USD',
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <EditIcon
                      classes={['align-center', 'pointer']}
                      height='16px'
                      width='16px'
                      onClick={() => setDisplayModalInvestment(true)}
                    />
                  </span>
                  {((!investmentDetail && investorState?.investor?.kycStatus === 'APPROVED') ||
                    (investmentDetail &&
                      investmentDetail?.kycStatus === 'APPROVED' &&
                      investmentDetail?.totalInvestment &&
                      investmentDetail?.investmentLimit &&
                      calculatePercentage(
                        investmentDetail?.totalInvestment,
                        investmentDetail?.investmentLimit,
                      ) > 90) ||
                    calculatePercentage(
                      investmentDetail?.totalInvestment,
                      investmentDetail?.investmentLimit,
                    ) < 0) && (
                    <div className='investment-warning-sub-heading mt-16 mr-16'>
                      <p>
                        {
                          'The investment amount is insufficient for investing in opportunity. Admin, please assign the investment limit for the investor.'
                        }
                      </p>
                    </div>
                  )}
                </div>
                {/* <div>
                  <P classes={['mb-8']} text="Quarterly Report" />
                  {displayModal ? (
                    <InputModal>
                      <Flex variant="column" classes={['input-modal']}>
                        <Flex variant="space-between">
                          <H4 text={'Quarterly Report'} />
                          <Close
                            height="24px"
                            onClick={() => {
                              setDisplayModal(false);
                              setQuarterlyFileValue('');
                              setQuarterlyFileName('');
                            }}
                          />
                        </Flex>
                        <Line />
                        <Flex variant="column" classes={['reply-section']}>
                          <InputFile
                            name={'quarterlyReport'}
                            type="doc"
                            handleFiles={async (file: any) => {
                              if (handleFileUpload && file) {
                                const data = await handleFileUpload(
                                  file as File,
                                  'doc'
                                );
                                if (data && data.data) {
                                  setQuarterlyFileValue(data.data.Key);
                                  setQuarterlyFileName(file && file.name);
                                }
                              }
                            }}
                            label=""
                          />
                          <Button
                            classes={['mt-24']}
                            variant="primary-btn"
                            text="Upload"
                            isDisabled={quarterlyFileValue ? false : true}
                            handleClick={() => {
                              handleQuarterlyReport(
                                quarterlyFileValue,
                                quarterlyFileName
                              );
                              setDisplayModal(false);
                              setQuarterlyFileValue('');
                              setQuarterlyFileName('');
                            }}
                          />
                        </Flex>
                      </Flex>
                    </InputModal>
                  ) : null}
                  <Button
                    classes={['auto-btn-width']}
                    variant="primary-btn"
                    handleClick={() => {
                      setDisplayModal(true);
                    }}
                    text={'Upload'}
                  />
                </div> */}
              </Flex>
            </Card>
          </Flex>

          <Flex variant={'row'} classes={['mt-24']}>
            <Card classes={['p-40', 'mr-16']}>
              <H5 text='Investor Important checklist' classes={['mb-16']} />
              <Flex variant='space-between'>
                <Span variant='bold' text='Email Verified' />

                {investorState?.investor && investorState?.investor?.isEmailVerified ? (
                  <CheckCircleIcon classes={['mr-4']} height='23px' />
                ) : (
                  <ReportIcon classes={['mr-4']} height='23px' />
                )}
              </Flex>

              <Flex variant='space-between' classes={['mt-4']}>
                <Span variant='bold' text='KYC Complete' />

                {kycData && kycData.status === KycStatus.APPROVED ? (
                  <CheckCircleIcon classes={['mr-4']} height='23px' />
                ) : (
                  <ToolTip
                    content={kycData && kycData.status}
                    contentClassNames={['tool-tip-container-fit']}
                  >
                    <span>
                      <ReportIcon classes={['mr-4']} height='23px' />
                    </span>
                  </ToolTip>
                )}
              </Flex>

              <Flex variant='space-between' classes={['mt-4']}>
                <Span variant='bold' text='Confidentiality Agreement Accepted' />

                {kycData && kycData.is_nda_accepted ? (
                  <CheckCircleIcon classes={['mr-4']} height='23px' />
                ) : (
                  <ReportIcon classes={['mr-4']} height='23px' />
                )}
              </Flex>

              <Flex variant='space-between' classes={['mt-4']}>
                <Span variant='bold' text='Risk Checklist Accepted' />

                {kycData && kycData.investment_essentials_agreement ? (
                  <CheckCircleIcon classes={['mr-4']} height='23px' />
                ) : (
                  <ReportIcon classes={['mr-4']} height='23px' />
                )}
              </Flex>

              <Flex variant='space-between' classes={['mt-4']}>
                <Span variant='bold' text='Terms of Business Accepted' />

                {kycData && kycData.accept_tob ? (
                  <CheckCircleIcon classes={['mr-4']} height='23px' />
                ) : (
                  <ReportIcon classes={['mr-4']} height='23px' />
                )}
              </Flex>

              <Flex variant='space-between' classes={['mt-4']}>
                <Span variant='bold' text='Transaction Checklist Accepted' />

                {kycData && kycData.transaction_checklist_agreement ? (
                  <CheckCircleIcon classes={['mr-4']} height='23px' />
                ) : (
                  <ReportIcon classes={['mr-4']} height='23px' />
                )}
              </Flex>

              <Flex variant='space-between' classes={['mt-4']}>
                <Span variant='bold' text='Agreement Signed' />

                {kycData && kycData.agreement_document_id ? (
                  <CheckCircleIcon classes={['mr-4']} height='23px' />
                ) : (
                  <ReportIcon classes={['mr-4']} height='23px' />
                )}
              </Flex>

              <Flex variant='space-between' classes={['mt-4']}>
                <Span variant='bold' text='Welcome Letter Shared' />

                {kycData && kycData?.metadata?.welcomeLetter ? (
                  <CheckCircleIcon classes={['mr-4']} height='23px' />
                ) : (
                  <ReportIcon classes={['mr-4']} height='23px' />
                )}
              </Flex>
            </Card>
          </Flex>

          <Line />

          <Flex variant='space-between'>
            <H4 classes={['mb-32']} text='Investor KYC' />
            {/* <Button
                    classes={['auto-btn-width']}
                    variant='primary-btn'
                    handleClick={() => {
                        approveKYC(investorState.investor.userId, investorState.kycDocuments.investorType ? investorState.kycDocuments.investorType : '')
                    }}
                    isDisabled={pendingDocuments && pendingDocuments?.length > 0 ? true : false}
                    text={investorState.kycDocuments.kycStatus === 'APPROVED' ? 'REJECT' : 'APPROVE' }
                    />  */}
          </Flex>

          <Flex variant='column'>
            <InvestorKYC
              kycData={kycData}
              investorState={investorState}
              investmentDetail={investmentDetail}
              // saveCommentOrApprove={saveCommentOrApprove}
              // changeHandler={changeHandler}
              // documents={investorState.kycDocuments.documents}
              // allDocuments={
              //   investorState.kycDocuments?.investorType
              //     ? kycMap[investorState.kycDocuments?.investorType]
              //     : kycMap.Individual
              // }
              // handleFilePreview={handleFilePreview}
            />
          </Flex>
        </>
      )}
    </>
  )
}

export { ProfileDetailsPresenter }
