import { KYCStatus } from '../types'

/* eslint-disable quotes */
export const EMAIL_ERROR = 'Email entered is not valid'
export const EMAIL_REQUIRED_ERROR = 'Email cannot be blank'
export const PASSWORD_ERROR =
  'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'
export const PASSWORD_REQUIRED_ERROR = 'Password cannot be blank'
export const CONFIRM_PASSOWRD_ERROR = 'Password and confirm password did not match'
export const CONFIRM_PASSOWRD_REQUIRED_ERROR = 'Confirm Password cannot be blank'
export const VERIFY_EMAIL = 'Please verify your email address'
export const ACTIVATE_ACCOUNT = 'to activate account.'
export const VERIFIED_SUCCESS = 'Verified successfully!'
export const VERIFY_TEXT = 'Welcome to Credibila, please log in to view'
export const VERIFY_SUBTEXT = 'opportunities and start investing.'
export const LOGIN = 'Log in'
export const BACK_TO_HOMEPAGE = 'Back to homepage'
export const SIGNUP_SUCCESS = 'Thank you for signing up!'
export const ERROR = 'error'
export const SUCCESS = 'success'
export const PROGRESS = 'progress'
export const WARNING = 'warning'
export const CONTINUE = 'Continue'
export const FORGOT_PASSWORD = 'Forgot your password?'
export const FORGOT_PASSWORD_TEXT01 = 'Enter the email associated with your'
export const FORGOT_PASSWORD_TEXT02 = 'account to reset your password:'
export const CHANGE_PASSWORD = 'Change password'
export const CHANGE_PASSWORD_TEXT01 = 'In order to protect your account, make sure your'
export const CHANGE_PASSWORD_TEXT02 = ' password matches the following criteria:'
export const RESET_INSTRUCTIONS = 'Instructions sent'
export const RESET_TEXT01 = 'Check your email inbox for further'
export const RESET_TEXT02 = 'instructions on password reset.'
export const COUNTRIES = [
  {
    text: 'Afghanistan',
    value: 'Afghanistan',
  },
  {
    text: 'Åland Islands',
    value: 'Åland Islands',
  },
  {
    text: 'Albania',
    value: 'Albania',
  },
  {
    text: 'Algeria',
    value: 'Algeria',
  },
  {
    text: 'American Samoa',
    value: 'American Samoa',
  },
  {
    text: 'Andorra',
    value: 'Andorra',
  },
  {
    text: 'Angola',
    value: 'Angola',
  },
  {
    text: 'Anguilla',
    value: 'Anguilla',
  },
  {
    text: 'Antarctica',
    value: 'Antarctica',
  },
  {
    text: 'Antigua and Barbuda',
    value: 'Antigua and Barbuda',
  },
  {
    text: 'Argentina',
    value: 'Argentina',
  },
  {
    text: 'Armenia',
    value: 'Armenia',
  },
  {
    text: 'Aruba',
    value: 'Aruba',
  },
  {
    text: 'Australia',
    value: 'Australia',
  },
  {
    text: 'Austria',
    value: 'Austria',
  },
  {
    text: 'Azerbaijan',
    value: 'Azerbaijan',
  },
  {
    text: 'Bahamas',
    value: 'Bahamas',
  },
  {
    text: 'Bahrain',
    value: 'Bahrain',
  },
  {
    text: 'Bangladesh',
    value: 'Bangladesh',
  },
  {
    text: 'Barbados',
    value: 'Barbados',
  },
  {
    text: 'Belarus',
    value: 'Belarus',
  },
  {
    text: 'Belgium',
    value: 'Belgium',
  },
  {
    text: 'Belize',
    value: 'Belize',
  },
  {
    text: 'Benin',
    value: 'Benin',
  },
  {
    text: 'Bermuda',
    value: 'Bermuda',
  },
  {
    text: 'Bhutan',
    value: 'Bhutan',
  },
  {
    text: 'Bolivia',
    value: 'Bolivia',
  },
  {
    text: 'Bosnia and Herzegovina',
    value: 'Bosnia and Herzegovina',
  },
  {
    text: 'Botswana',
    value: 'Botswana',
  },
  {
    text: 'Bouvet Island',
    value: 'Bouvet Island',
  },
  {
    text: 'Brazil',
    value: 'Brazil',
  },
  {
    text: 'British Indian Ocean Territory',
    value: 'British Indian Ocean Territory',
  },
  {
    text: 'Brunei Darussalam',
    value: 'Brunei Darussalam',
  },
  {
    text: 'Bulgaria',
    value: 'Bulgaria',
  },
  {
    text: 'Burkina Faso',
    value: 'Burkina Faso',
  },
  {
    text: 'Burundi',
    value: 'Burundi',
  },
  {
    text: 'Cambodia',
    value: 'Cambodia',
  },
  {
    text: 'Cameroon',
    value: 'Cameroon',
  },
  {
    text: 'Canada',
    value: 'Canada',
  },
  {
    text: 'Cape Verde',
    value: 'Cape Verde',
  },
  {
    text: 'Cayman Islands',
    value: 'Cayman Islands',
  },
  {
    text: 'Central African Republic',
    value: 'Central African Republic',
  },
  {
    text: 'Chad',
    value: 'Chad',
  },
  {
    text: 'Chile',
    value: 'Chile',
  },
  {
    text: 'China',
    value: 'China',
  },
  {
    text: 'Christmas Island',
    value: 'Christmas Island',
  },
  {
    text: 'Cocos (Keeling) Islands',
    value: 'Cocos (Keeling) Islands',
  },
  {
    text: 'Colombia',
    value: 'Colombia',
  },
  {
    text: 'Comoros',
    value: 'Comoros',
  },
  {
    text: 'Congo',
    value: 'Congo',
  },
  {
    text: 'Congo, The Democratic Republic of the',
    value: 'Congo, The Democratic Republic of the',
  },
  {
    text: 'Cook Islands',
    value: 'Cook Islands',
  },
  {
    text: 'Costa Rica',
    value: 'Costa Rica',
  },
  {
    text: "Cote D'Ivoire",
    value: "Cote D'Ivoire",
  },
  {
    text: 'Croatia',
    value: 'Croatia',
  },
  {
    text: 'Cuba',
    value: 'Cuba',
  },
  {
    text: 'Cyprus',
    value: 'Cyprus',
  },
  {
    text: 'Czech Republic',
    value: 'Czech Republic',
  },
  {
    text: 'Denmark',
    value: 'Denmark',
  },
  {
    text: 'Djibouti',
    value: 'Djibouti',
  },
  {
    text: 'Dominica',
    value: 'Dominica',
  },
  {
    text: 'Dominican Republic',
    value: 'Dominican Republic',
  },
  {
    text: 'Ecuador',
    value: 'Ecuador',
  },
  {
    text: 'Egypt',
    value: 'Egypt',
  },
  {
    text: 'El Salvador',
    value: 'El Salvador',
  },
  {
    text: 'Equatorial Guinea',
    value: 'Equatorial Guinea',
  },
  {
    text: 'Eritrea',
    value: 'Eritrea',
  },
  {
    text: 'Estonia',
    value: 'Estonia',
  },
  {
    text: 'Ethiopia',
    value: 'Ethiopia',
  },
  {
    text: 'Falkland Islands (Malvinas)',
    value: 'Falkland Islands (Malvinas)',
  },
  {
    text: 'Faroe Islands',
    value: 'Faroe Islands',
  },
  {
    text: 'Fiji',
    value: 'Fiji',
  },
  {
    text: 'Finland',
    value: 'Finland',
  },
  {
    text: 'France',
    value: 'France',
  },
  {
    text: 'French Guiana',
    value: 'French Guiana',
  },
  {
    text: 'French Polynesia',
    value: 'French Polynesia',
  },
  {
    text: 'French Southern Territories',
    value: 'French Southern Territories',
  },
  {
    text: 'Gabon',
    value: 'Gabon',
  },
  {
    text: 'Gambia',
    value: 'Gambia',
  },
  {
    text: 'Georgia',
    value: 'Georgia',
  },
  {
    text: 'Germany',
    value: 'Germany',
  },
  {
    text: 'Ghana',
    value: 'Ghana',
  },
  {
    text: 'Gibraltar',
    value: 'Gibraltar',
  },
  {
    text: 'Greece',
    value: 'Greece',
  },
  {
    text: 'Greenland',
    value: 'Greenland',
  },
  {
    text: 'Grenada',
    value: 'Grenada',
  },
  {
    text: 'Guadeloupe',
    value: 'Guadeloupe',
  },
  {
    text: 'Guam',
    value: 'Guam',
  },
  {
    text: 'Guatemala',
    value: 'Guatemala',
  },
  {
    text: 'Guernsey',
    value: 'Guernsey',
  },
  {
    text: 'Guinea',
    value: 'Guinea',
  },
  {
    text: 'Guinea-Bissau',
    value: 'Guinea-Bissau',
  },
  {
    text: 'Guyana',
    value: 'Guyana',
  },
  {
    text: 'Haiti',
    value: 'Haiti',
  },
  {
    text: 'Heard Island and Mcdonald Islands',
    value: 'Heard Island and Mcdonald Islands',
  },
  {
    text: 'Holy See (Vatican City State)',
    value: 'Holy See (Vatican City State)',
  },
  {
    text: 'Honduras',
    value: 'Honduras',
  },
  {
    text: 'Hong Kong',
    value: 'Hong Kong',
  },
  {
    text: 'Hungary',
    value: 'Hungary',
  },
  {
    text: 'Iceland',
    value: 'Iceland',
  },
  {
    text: 'India',
    value: 'India',
  },
  {
    text: 'Indonesia',
    value: 'Indonesia',
  },
  {
    text: 'Iran, Islamic Republic Of',
    value: 'Iran, Islamic Republic Of',
  },
  {
    text: 'Iraq',
    value: 'Iraq',
  },
  {
    text: 'Ireland',
    value: 'Ireland',
  },
  {
    text: 'Isle of Man',
    value: 'Isle of Man',
  },
  {
    text: 'Israel',
    value: 'Israel',
  },
  {
    text: 'Italy',
    value: 'Italy',
  },
  {
    text: 'Jamaica',
    value: 'Jamaica',
  },
  {
    text: 'Japan',
    value: 'Japan',
  },
  {
    text: 'Jersey',
    value: 'Jersey',
  },
  {
    text: 'Jordan',
    value: 'Jordan',
  },
  {
    text: 'Kazakhstan',
    value: 'Kazakhstan',
  },
  {
    text: 'Kenya',
    value: 'Kenya',
  },
  {
    text: 'Kiribati',
    value: 'Kiribati',
  },
  {
    text: "Korea, Democratic People'S Republic of",
    value: "Korea, Democratic People'S Republic of",
  },
  {
    text: 'Korea, Republic of',
    value: 'Korea, Republic of',
  },
  {
    text: 'Kuwait',
    value: 'Kuwait',
  },
  {
    text: 'Kyrgyzstan',
    value: 'Kyrgyzstan',
  },
  {
    text: "Lao People'S Democratic Republic",
    value: "Lao People'S Democratic Republic",
  },
  {
    text: 'Latvia',
    value: 'Latvia',
  },
  {
    text: 'Lebanon',
    value: 'Lebanon',
  },
  {
    text: 'Lesotho',
    value: 'Lesotho',
  },
  {
    text: 'Liberia',
    value: 'Liberia',
  },
  {
    text: 'Libyan Arab Jamahiriya',
    value: 'Libyan Arab Jamahiriya',
  },
  {
    text: 'Liechtenstein',
    value: 'Liechtenstein',
  },
  {
    text: 'Lithuania',
    value: 'Lithuania',
  },
  {
    text: 'Luxembourg',
    value: 'Luxembourg',
  },
  {
    text: 'Macao',
    value: 'Macao',
  },
  {
    text: 'Macedonia, The Former Yugoslav Republic of',
    value: 'Macedonia, The Former Yugoslav Republic of',
  },
  {
    text: 'Madagascar',
    value: 'Madagascar',
  },
  {
    text: 'Malawi',
    value: 'Malawi',
  },
  {
    text: 'Malaysia',
    value: 'Malaysia',
  },
  {
    text: 'Maldives',
    value: 'Maldives',
  },
  {
    text: 'Mali',
    value: 'Mali',
  },
  {
    text: 'Malta',
    value: 'Malta',
  },
  {
    text: 'Marshall Islands',
    value: 'Marshall Islands',
  },
  {
    text: 'Martinique',
    value: 'Martinique',
  },
  {
    text: 'Mauritania',
    value: 'Mauritania',
  },
  {
    text: 'Mauritius',
    value: 'Mauritius',
  },
  {
    text: 'Mayotte',
    value: 'Mayotte',
  },
  {
    text: 'Mexico',
    value: 'Mexico',
  },
  {
    text: 'Micronesia, Federated States of',
    value: 'Micronesia, Federated States of',
  },
  {
    text: 'Moldova, Republic of',
    value: 'Moldova, Republic of',
  },
  {
    text: 'Monaco',
    value: 'Monaco',
  },
  {
    text: 'Mongolia',
    value: 'Mongolia',
  },
  {
    text: 'Montserrat',
    value: 'Montserrat',
  },
  {
    text: 'Morocco',
    value: 'Morocco',
  },
  {
    text: 'Mozambique',
    value: 'Mozambique',
  },
  {
    text: 'Myanmar',
    value: 'Myanmar',
  },
  {
    text: 'Namibia',
    value: 'Namibia',
  },
  {
    text: 'Nauru',
    value: 'Nauru',
  },
  {
    text: 'Nepal',
    value: 'Nepal',
  },
  {
    text: 'Netherlands',
    value: 'Netherlands',
  },
  {
    text: 'Netherlands Antilles',
    value: 'Netherlands Antilles',
  },
  {
    text: 'New Caledonia',
    value: 'New Caledonia',
  },
  {
    text: 'New Zealand',
    value: 'New Zealand',
  },
  {
    text: 'Nicaragua',
    value: 'Nicaragua',
  },
  {
    text: 'Niger',
    value: 'Niger',
  },
  {
    text: 'Nigeria',
    value: 'Nigeria',
  },
  {
    text: 'Niue',
    value: 'Niue',
  },
  {
    text: 'Norfolk Island',
    value: 'Norfolk Island',
  },
  {
    text: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands',
  },
  {
    text: 'Norway',
    value: 'Norway',
  },
  {
    text: 'Oman',
    value: 'Oman',
  },
  {
    text: 'Pakistan',
    value: 'Pakistan',
  },
  {
    text: 'Palau',
    value: 'Palau',
  },
  {
    text: 'Palestinian Territory, Occupied',
    value: 'Palestinian Territory, Occupied',
  },
  {
    text: 'Panama',
    value: 'Panama',
  },
  {
    text: 'Papua New Guinea',
    value: 'Papua New Guinea',
  },
  {
    text: 'Paraguay',
    value: 'Paraguay',
  },
  {
    text: 'Peru',
    value: 'Peru',
  },
  {
    text: 'Philippines',
    value: 'Philippines',
  },
  {
    text: 'Pitcairn',
    value: 'Pitcairn',
  },
  {
    text: 'Poland',
    value: 'Poland',
  },
  {
    text: 'Portugal',
    value: 'Portugal',
  },
  {
    text: 'Puerto Rico',
    value: 'Puerto Rico',
  },
  {
    text: 'Qatar',
    value: 'Qatar',
  },
  {
    text: 'Reunion',
    value: 'Reunion',
  },
  {
    text: 'Romania',
    value: 'Romania',
  },
  {
    text: 'Russian Federation',
    value: 'Russian Federation',
  },
  {
    text: 'RWANDA',
    value: 'RWANDA',
  },
  {
    text: 'Saint Helena',
    value: 'Saint Helena',
  },
  {
    text: 'Saint Kitts and Nevis',
    value: 'Saint Kitts and Nevis',
  },
  {
    text: 'Saint Lucia',
    value: 'Saint Lucia',
  },
  {
    text: 'Saint Pierre and Miquelon',
    value: 'Saint Pierre and Miquelon',
  },
  {
    text: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines',
  },
  {
    text: 'Samoa',
    value: 'Samoa',
  },
  {
    text: 'San Marino',
    value: 'San Marino',
  },
  {
    text: 'Sao Tome and Principe',
    value: 'Sao Tome and Principe',
  },
  {
    text: 'Saudi Arabia',
    value: 'Saudi Arabia',
  },
  {
    text: 'Senegal',
    value: 'Senegal',
  },
  {
    text: 'Serbia and Montenegro',
    value: 'Serbia and Montenegro',
  },
  {
    text: 'Seychelles',
    value: 'Seychelles',
  },
  {
    text: 'Sierra Leone',
    value: 'Sierra Leone',
  },
  {
    text: 'Singapore',
    value: 'Singapore',
  },
  {
    text: 'Slovakia',
    value: 'Slovakia',
  },
  {
    text: 'Slovenia',
    value: 'Slovenia',
  },
  {
    text: 'Solomon Islands',
    value: 'Solomon Islands',
  },
  {
    text: 'Somalia',
    value: 'Somalia',
  },
  {
    text: 'South Africa',
    value: 'South Africa',
  },
  {
    text: 'South Georgia and the South Sandwich Islands',
    value: 'South Georgia and the South Sandwich Islands',
  },
  {
    text: 'Spain',
    value: 'Spain',
  },
  {
    text: 'Sri Lanka',
    value: 'Sri Lanka',
  },
  {
    text: 'Sudan',
    value: 'Sudan',
  },
  {
    text: 'Suriname',
    value: 'Suriname',
  },
  {
    text: 'Svalbard and Jan Mayen',
    value: 'Svalbard and Jan Mayen',
  },
  {
    text: 'Swaziland',
    value: 'Swaziland',
  },
  {
    text: 'Sweden',
    value: 'Sweden',
  },
  {
    text: 'Switzerland',
    value: 'Switzerland',
  },
  {
    text: 'Syrian Arab Republic',
    value: 'Syrian Arab Republic',
  },
  {
    text: 'Taiwan, Province of China',
    value: 'Taiwan, Province of China',
  },
  {
    text: 'Tajikistan',
    value: 'Tajikistan',
  },
  {
    text: 'Tanzania, United Republic of',
    value: 'Tanzania, United Republic of',
  },
  {
    text: 'Thailand',
    value: 'Thailand',
  },
  {
    text: 'Timor-Leste',
    value: 'Timor-Leste',
  },
  {
    text: 'Togo',
    value: 'Togo',
  },
  {
    text: 'Tokelau',
    value: 'Tokelau',
  },
  {
    text: 'Tonga',
    value: 'Tonga',
  },
  {
    text: 'Trinidad and Tobago',
    value: 'Trinidad and Tobago',
  },
  {
    text: 'Tunisia',
    value: 'Tunisia',
  },
  {
    text: 'Turkey',
    value: 'Turkey',
  },
  {
    text: 'Turkmenistan',
    value: 'Turkmenistan',
  },
  {
    text: 'Turks and Caicos Islands',
    value: 'Turks and Caicos Islands',
  },
  {
    text: 'Tuvalu',
    value: 'Tuvalu',
  },
  {
    text: 'Uganda',
    value: 'Uganda',
  },
  {
    text: 'Ukraine',
    value: 'Ukraine',
  },
  {
    text: 'United Arab Emirates',
    value: 'United Arab Emirates',
  },
  {
    text: 'United Kingdom',
    value: 'United Kingdom',
  },
  {
    text: 'United States',
    value: 'United States',
  },
  {
    text: 'United States Minor Outlying Islands',
    value: 'United States Minor Outlying Islands',
  },
  {
    text: 'Uruguay',
    value: 'Uruguay',
  },
  {
    text: 'Uzbekistan',
    value: 'Uzbekistan',
  },
  {
    text: 'Vanuatu',
    value: 'Vanuatu',
  },
  {
    text: 'Venezuela',
    value: 'Venezuela',
  },
  {
    text: 'Viet Nam',
    value: 'Viet Nam',
  },
  {
    text: 'Virgin Islands, British',
    value: 'Virgin Islands, British',
  },
  {
    text: 'Virgin Islands, U.S.',
    value: 'Virgin Islands, U.S.',
  },
  {
    text: 'Wallis and Futuna',
    value: 'Wallis and Futuna',
  },
  {
    text: 'Western Sahara',
    value: 'Western Sahara',
  },
  {
    text: 'Yemen',
    value: 'Yemen',
  },
  {
    text: 'Zambia',
    value: 'Zambia',
  },
  {
    text: 'Zimbabwe',
    value: 'Zimbabwe',
  },
]

export const ASSET_CLASS = [
  { text: 'Private Equity', value: 'Private Equity' },
  { text: 'Real Estate', value: 'Real Estate' },
  { text: 'Funds of Funds', value: 'Funds of Funds' },
  { text: 'Fixed Income', value: 'Fixed Income' },
  { text: 'Listed Equities', value: 'Listed Equities' },
  { text: 'Trade Finance', value: 'Trade Finance' },
  { text: 'Mutual Fund', value: 'Mutual Fund' },
]

export const projectFilters = [
  ...ASSET_CLASS,
  { text: 'High commitence rate', value: 'High commitence rate' },
  { text: 'Published opportunity', value: 'PUBLISHED' },
  { text: 'Unpublished opportunity', value: 'UNPUBLISHED' },
]

export const projectSort = [
  { text: 'Recent Closing Date', value: 'CLOSING_DATE_ASC' },
  { text: 'Oldest Closing Date', value: 'CLOSING_DATE_DESC' },
  { text: 'High ticket Size', value: 'TICKET_SIZE_DESC' },
  { text: 'Low ticket Size', value: 'TICKET_SIZE_ASC' },
]

export const investmentFilters = [
  { text: 'Status Initiated', value: 'INVESTMENT_INITIATED' },
  { text: 'Status Approved', value: 'INVESTMENT_APPROVED' },
  { text: 'Status Rejected', value: 'INVESTMENT_REJECTED' },
  { text: 'Status Closed', value: 'INVESTMENT_CLOSED' },
]

export const investmentSort = [
  { text: 'Recent Investment', value: 'CREATED_AT_DESC' },
  { text: 'Oldest Investment', value: 'CREATED_AT_ASC' },
  ...projectSort,

  // TODO :: enable when implemented
  // { "text": "High ROI", "value": "ROI_DESC" },
  // { "text": "Low ROI", "value": "ROI_ASC" },
]

export const investmentStatus = [
  { text: 'Fund Allocation In Progress', value: 'Fund Allocation In Progress' },
  { text: 'Payment Accepted', value: 'Payment Accepted' },
  { text: 'Payment Rejected', value: 'Payment Rejected' },
  { text: 'Fund Allocation Rejected', value: 'Fund Allocation Rejected' },
  { text: 'Fund Allocation Approved', value: 'Fund Allocation Approved' },
]
export const documentStatus = [
  { text: '', value: '' },
  { text: 'Approve', value: 'APPROVED' },
  { text: 'Decline', value: 'DECLINED' },
]
export const currencyDropdown = [
  { text: '', value: '' },
  { text: 'K', value: 'K' },
  { text: 'M', value: 'M' },
  { text: 'B', value: 'B' },
]
export const isRequiredMessage = (field: string) => `${field} cannot be empty`

export const BACK_TO_OPPORTUNITY = 'Back to Opportunities'
export const ABOUT_THE_COMPANY = 'About the Company'
export const ABOUT_COMPANY_CONTENT = `A technology company that focuses on E-commerce for Justice Services across the globe under
the Private Justice System. They  have designed, built & operate World’s First Private Digital Court –
a Next Generation Platform that connects Disputants with ADR Practitioners, i.e., Arbitrators, Conciliators, Mediators, Assisted Negotiators, Lawyers, etc., to find & hire their own Neutrals
and Lawyers to seek their Dispute Resolution Services and further enables all the participants
to perform the End-to-End Dispute Resolution Process on its Ultra-Advanced, Innovative & Zero Contact Dispute Resolution Platform, supplemented by several Eco-System Services to offer
optimal outcomes`
export const CLIENT_ENGAGEMENTS = 'Client Engagements'
export const KONCEPTED = 'Koncepted'
export const INVEST = 'Invest'
export const SAVE_FOR_LATER = 'Save for later'
export const VIEW_DOCUMENTS = 'View documents'
export const DEAL_CONTOURS = 'Deal Contours'
export const DEAL_LIST_DATA = [
  'Fund has 15% stake in the Company.',
  'Investment in the form of issuance of Compulsorily Convertible Preference Shares(CCPS) with senior rights.',
  'Fund utilization:  Geographic expansion, augmentation of technical capabilities,and for the working capital requirements of the Business in line with the Business Plan.',
  'Fund is entitled to have 1 (one) Non-executive Board seat.',
  'Fund is protected for its Investment against any Dilutive Issuance by the Company.',
]
export const QUESTION_FOR_PROJECT = 'Have a question for opportunity owners?'
export const GET_IN_TOUCH_WITH_OWNER =
  'Get in touch with the owners and learn more about the opportunity.'
export const CONTACT_US = 'Contact us'
export const investorFilter = [
  { text: 'KYC pending for upload', value: 'KYC_PENDING' },
  { text: 'KYC pending for approval', value: 'KYC_SENT_FOR_APPROVAL' },
  { text: 'KYC rejected', value: 'KYC_REJECTED' },
  { text: 'KYC approved', value: 'KYC_APPROVED' },
  { text: 'NDA pending', value: 'NDA_PENDING' },
  { text: 'NDA accepted', value: 'NDA_COMPLETED' },
]

export const InvestmentStatus = [
  { text: '', value: '' },
  { text: 'Approved', value: 'APPROVED' },
  { text: 'Closed', value: 'CLOSED' },
  { text: 'Rejected', value: 'REJECTED' },
]

export const queriesFilter = [
  {
    text: 'Issue with Investment – Immediate Attention Required',
    value: 'Issue with Investment',
  },
  {
    text: 'Issue with Withdrawing Investment – Immediate Attention Required',
    value: 'Issue with Investment Withdrawal',
  },
  {
    text: 'Issue with Platform Functionality – Need Assistance',
    value: 'Issue with Platform Functionality',
  },
  { text: 'Problem with KYC Verification – Need Help', value: 'Issue with KYC Verification' },
  { text: 'Concern Regarding Refund – Immediate Help Needed', value: 'Issue with Refund' },
  { text: 'Help Needed: General Query', value: 'General Query' },
  { text: 'Problem with Account – Need Assistance', value: 'Issue with Account' },
]

export const queriesSort = [
  { text: 'Recent Queries', value: 'CREATED_AT_DESC' },
  { text: 'Oldest Queries', value: 'CREATED_AT_ASC' },
]

export const kycStatusMap: Record<Partial<KYCStatus>, string> = {
  NOT_STARTED: 'Not started',
  INITIATED: 'Initiated',
  PENDING: 'Sent for approval',
  REJECTED: 'Rejected',
  APPROVED: 'Approved',
  RESUBMIT: 'Resubmission needed',
}

export const SHIPMENT_STATUSES = [
  {
    text: 'Shipment Loading',
    value: 'Shipment Loading',
  },
  {
    text: 'Shipment Loaded',
    value: 'Shipment Loaded',
  },
  {
    text: 'On Voyage',
    value: 'On Voyage',
  },
  {
    text: 'Discharged',
    value: 'Discharged',
  },
  {
    text: 'Custom Cleared',
    value: 'Custom Cleared',
  },
]

export const orderFilters = [
  ...ASSET_CLASS,
  { text: 'Waiting for approval', value: 'INITIATED' },
  { text: 'Started by investors', value: 'PENDING' },
]

export const stuckAtInvestmentMessage: Record<
  number,
  { stepText: string; stepDescription: string }
> = {
  2: {
    stepText: 'Stuck at step 2',
    stepDescription: 'User is stuck at step 2 - Sign Agreement step',
  },
  3: {
    stepText: 'Stuck at step 3',
    stepDescription: 'User is stuck at step 3 - Payment proof upload step',
  },
}

export const ordersColumnDescription: Record<string, string> = {
  order_creation_date:
    'The date on which the investor completed all the required steps and successfully placed the investment (buy/sell) request.',
  allocation_date:
    'The date mentioned on the contract note, representing when the units were actually allocated or withdrawn.',
}

export const tableStatusMappings = {
  dormant: {
    tooltipText: 'This user is dormant',
    class: 'dormant-user',
  },
  'incomplete-order': {
    tooltipText: 'This order is not completed',
    class: 'incomplete-order',
  },
  'incomplete-order-3': {
    tooltipText: 'This order is not completed - SWIFT Copy / Transaction Number not uploaded',
    class: 'incomplete-order-3',
  },
}
