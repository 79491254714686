/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import {
  H2,
  H6,
  Line,
  Button,
  Input,
  Flex,
  CheckboxInput,
  TextboxInput,
  SelectDropdown,
  InputFile,
  Toggle,
  DeleteIcon,
} from '../../lib/components'
import { IFormProps, NewOpportunity, FileDataProps } from '../../lib/types'
import { FormikProps, Formik, FieldArray } from 'formik'
import { DatePicker } from '../../lib/components/DatePicker'
import { UploadedImage } from './uploaded-image'
import { COUNTRIES, ASSET_CLASS } from '../../lib/utils/message'
import { createSlug, getFileKeyFromSignedUrl } from '../../lib/utils/helpers'
import { projectServices } from '../../services'
import './project-form.scss'

const ProjectFormPresenter = ({
  initialValues,
  handleFormSubmit,
  validationSchema,
  handleFileUpload,
  handleErrorMessage,
}: IFormProps<NewOpportunity, NewOpportunity>) => {
  const params = useParams()
  const { id, projectSlug } = params
  const [signedImageUrl, setSignedImageUrl] = useState({
    cover_image: '',
    images: [''],
  })
  const [uploadFilesMetaData, setUploadFilesMetaData] = useState<{
    images: FileDataProps[]
    video: FileDataProps
    documents: FileDataProps[]
    cover_image: FileDataProps
  }>({
    images: [],
    video: {} as FileDataProps,
    documents: [],
    cover_image: {} as FileDataProps,
  })

  useEffect(() => {
    // Creating signed URLs and metadata for images in edit flow
    const populateSignedImages = async () => {
      const imagesPreview =
        initialValues?.images && initialValues?.images?.[0] !== ''
          ? await Promise.all(initialValues?.images.map(async (img) => generatePreview(img)))
          : null
      const coverImagePreview = await generatePreview(initialValues.cover_image)
      setSignedImageUrl({
        cover_image: coverImagePreview.url,
        images: imagesPreview ? imagesPreview?.map((img) => img.url) : [],
      })
      setUploadFilesMetaData((oldData) => ({
        ...oldData,
        images: imagesPreview
          ? imagesPreview.map((img) => ({
              name: img.metadata.filename,
              type: img.metadata.type,
              size: img.metadata.size,
              date: img.metadata.lastModified,
            }))
          : [],
        cover_image: coverImagePreview
          ? {
              name: coverImagePreview.metadata.filename,
              type: coverImagePreview.metadata.type,
              size: coverImagePreview.metadata.size,
              date: coverImagePreview.metadata.lastModified,
            }
          : ({} as FileDataProps),
      }))
    }

    // Creating metadata for uploaded files in edit flow
    const populateUploadMetadata = async () => {
      const videoPreview = await generatePreview(initialValues.video)

      const documentsPreview =
        initialValues?.documents && initialValues?.documents?.[0]?.title !== ''
          ? await Promise.all(initialValues.documents.map(async (doc) => generatePreview(doc.url)))
          : null

      setUploadFilesMetaData((oldData) => ({
        ...oldData,
        video: videoPreview
          ? {
              name: videoPreview.metadata.filename,
              type: videoPreview.metadata.type,
              size: videoPreview.metadata.size,
              date: videoPreview.metadata.lastModified,
            }
          : ({} as FileDataProps),
        documents: documentsPreview
          ? documentsPreview.map((doc) => ({
              name: doc.metadata.filename,
              type: doc.metadata.type,
              size: doc.metadata.size,
              date: doc.metadata.lastModified,
            }))
          : [],
      }))
    }

    // Executing only in edit flow
    if (initialValues.opportunity_title !== '') {
      populateSignedImages()
      populateUploadMetadata()
    }
  }, [initialValues])

  const getError = (errorObj: any, fieldName: string | null = null) => {
    if (errorObj instanceof Date) {
      return errorObj.toString()
    }
    if (Array.isArray(errorObj)) {
      if (fieldName === 'documents') {
        const errors = errorObj
          .map((err, idx) => (err ? `Document-${idx + 1}: ${err.url}` : ''))
          .join(';')
        return `${fieldName}: ${errors}`
      }
      return errorObj.length > 0 ? errorObj[0] : ''
    }
    if (typeof errorObj === 'object' && errorObj !== null && !(errorObj instanceof Function)) {
      return JSON.stringify(errorObj)
    }
    return errorObj
  }

  const generatePreview = async (key: any) => {
    const newURL = (key as any).replaceAll('/', '%2F')

    const signedUrl = getFileKeyFromSignedUrl(newURL)
    const data = await projectServices.previewFile(signedUrl)
    if (data && data.data && data.status === 200) return data.data
    return ''
  }

  return (
    <>
      {' '}
      <div className='project-form-container'>
        <H2
          classes={['mb-48']}
          text={id && projectSlug ? 'Edit Opportunity' : 'Add New Opportunity'}
        />
        <Formik
          enableReinitialize
          initialValues={initialValues}
          handleFileUpload={handleFileUpload}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {(formik) => {
            const {
              values,
              touched,
              errors,
              dirty,
              setFieldValue,
              handleSubmit,
            }: FormikProps<typeof initialValues> = formik
            return (
              <form>
                <H6 classes={['mb-32']} text='Opportunity Details' />
                <Line />
                <Flex classes={['form-flex-direction']} variant='space-between'>
                  <div className='field-container'>
                    <Input
                      name='opportunity_title'
                      labelText='Opportunity Name *'
                      placeholder='Opportunity Name'
                      type='text'
                      required
                      value={values.opportunity_title}
                      handleChange={(e) => {
                        setFieldValue('opportunity_title', e.target.value)
                        setFieldValue('opportunity_slug', e.target.value)
                      }}
                      dirty={dirty}
                      errors={{
                        opportunity_title:
                          !!errors && errors.opportunity_title ? errors.opportunity_title : null,
                      }}
                      touched={{
                        opportunity_title: !!touched && !!touched.opportunity_title,
                      }}
                    />
                  </div>
                  <div className='field-container'>
                    <Input
                      name='opportunity_slug'
                      labelText='Opportunity Slug *'
                      placeholder='Unique Opportunity Slug'
                      type='text'
                      required
                      value={createSlug(values.opportunity_title)}
                      disabled
                    />
                  </div>
                  <div className='field-container'>
                    <div className='input-label'> Asset Class *</div>
                    <SelectDropdown
                      name='assetClass'
                      id='assetClassId'
                      formId=''
                      list={ASSET_CLASS}
                      value={values.asset_class}
                      handleChange={(val: any) => setFieldValue('asset_class', val)}
                      dirty={dirty}
                      errors={{
                        asset_class: !!errors && errors.asset_class ? errors.asset_class : null,
                      }}
                      touched={{
                        asset_class: !!touched && !!touched.asset_class,
                      }}
                    />
                  </div>
                </Flex>
                <Flex classes={['form-flex-direction']} variant='space-between'>
                  <div className='field-container'>
                    <div className='input-label'> Jurisdiction *</div>
                    <SelectDropdown
                      name='location'
                      id='countryDropdownId'
                      formId=''
                      list={COUNTRIES}
                      value={values.jurisdiction}
                      handleChange={(val: any) => setFieldValue('jurisdiction', val)}
                      dirty={dirty}
                      errors={{
                        location: !!errors && errors.jurisdiction ? errors.jurisdiction : null,
                      }}
                      touched={{ location: !!touched && !!touched.jurisdiction }}
                    />
                  </div>
                  <div className='field-container'>
                    <Input
                      name='currency_code'
                      labelText='Currency Code *'
                      placeholder='Currency Code'
                      type='text'
                      required
                      value={values.currency_code}
                      handleChange={(e) => setFieldValue('currency_code', e.target.value)}
                      dirty={dirty}
                      errors={{
                        currency_code:
                          !!errors && errors.currency_code ? errors.currency_code : null,
                      }}
                      touched={{
                        currency_code: !!touched && !!touched.currency_code,
                      }}
                    />
                  </div>
                  <div className='field-container'>
                    <Input
                      name='ticket_size'
                      labelText='Ticket Size (in USD)*'
                      placeholder='Ticket Size'
                      type='number'
                      required
                      value={values.ticket_size.toString()}
                      handleChange={(e) => setFieldValue('ticket_size', e.target.value)}
                      dirty={dirty}
                      errors={{
                        ticketSize: !!errors && errors.ticket_size ? errors.ticket_size : null,
                      }}
                      touched={{
                        ticketSize: !!touched && !!touched.ticket_size,
                      }}
                    />
                  </div>
                </Flex>
                <Flex classes={['form-flex-direction']} variant='space-between'>
                  <div className='field-container'>
                    <div className='input-label'> Closing Date *</div>
                    <DatePicker
                      value={values.closing_date ? new Date(values.closing_date) : new Date()}
                      format='MMMM D, YYYY'
                      classes={['form-control', 'mt-8']}
                      minDate={new Date()}
                      changeHandler={(date) => setFieldValue('closing_date', date)}
                    />
                  </div>
                  <div className='field-container'>
                    <Input
                      name='investment_return'
                      labelText='Investment Return'
                      placeholder='Investment Return'
                      type='text'
                      required
                      value={values.investment_return.toString()}
                      handleChange={(e) => setFieldValue('investment_return', e.target.value)}
                      dirty={dirty}
                      errors={{
                        investment_return:
                          !!errors && errors.investment_return ? errors.investment_return : null,
                      }}
                      touched={{
                        investment_return: !!touched && !!touched.investment_return,
                      }}
                    />
                  </div>
                  <div className='field-container'>
                    <Input
                      name='raiseGoal'
                      labelText='Raise Goal (in USD)*'
                      placeholder='Total investment available to invest'
                      type='number'
                      required
                      value={values.raise_goal.toString()}
                      handleChange={(e) => setFieldValue('raise_goal', e.target.value)}
                      dirty={dirty}
                      errors={{
                        raise_goal: !!errors && errors.raise_goal ? errors.raise_goal : null,
                      }}
                      touched={{ raise_goal: !!touched && !!touched.raise_goal }}
                    />
                  </div>
                </Flex>

                <Flex classes={['form-flex-direction']} variant='space-between'>
                  <div className='field-container'>
                    <Input
                      name='website'
                      labelText='Website'
                      required
                      value={values.metadata.company.website}
                      handleChange={(e) =>
                        setFieldValue('metadata.company.website', e.target.value)
                      }
                      dirty={dirty}
                      errors={{
                        website:
                          !!errors && errors.metadata?.company?.website
                            ? errors.metadata?.company?.website
                            : null,
                      }}
                      touched={{
                        website: !!touched && !!touched.metadata?.company?.website,
                      }}
                    />
                  </div>

                  <div className='field-container'>
                    <Input
                      name='coupon_payment_interval_in_months'
                      labelText='Coupon payment interval'
                      placeholder='Coupon payment interval in months'
                      type='number'
                      required
                      value={values?.coupon_payment_interval_in_months?.toString()}
                      handleChange={(e) =>
                        setFieldValue('coupon_payment_interval_in_months', e.target.value)
                      }
                      dirty={dirty}
                      errors={{
                        coupon_payment_interval_in_months:
                          !!errors && errors.coupon_payment_interval_in_months
                            ? errors.coupon_payment_interval_in_months
                            : null,
                      }}
                      touched={{
                        investment_return: !!touched && !!touched.coupon_payment_interval_in_months,
                      }}
                    />
                  </div>
                </Flex>

                <Flex classes={['form-flex-direction']} variant='space-between'>
                  <div className='field-container'>
                    <TextboxInput
                      name='opportunity_summary'
                      labelText='Opportunity Summary *'
                      // placeholder='Max 500 characters allowed'
                      required
                      value={values.opportunity_summary}
                      handleChange={(e) => setFieldValue('opportunity_summary', e.target.value)}
                      dirty={dirty}
                      errors={{
                        opportunity_summary:
                          !!errors && errors.opportunity_summary
                            ? errors.opportunity_summary
                            : null,
                      }}
                      touched={{
                        opportunity_summary: !!touched && !!touched.opportunity_summary,
                      }}
                      // infoText={`Character ${values.opportunity_summary.length} out of 500`}
                    />
                  </div>
                </Flex>

                <Flex classes={['form-flex-direction', 'mb-12']} variant='space-between'>
                  <div className='field-container'>
                    <TextboxInput
                      name='short_opportunity_summary'
                      labelText='Short Opportunity Summary *'
                      placeholder='Max 255 characters allowed'
                      required
                      value={values.short_opportunity_summary}
                      handleChange={(e) =>
                        setFieldValue('short_opportunity_summary', e.target.value)
                      }
                      dirty={dirty}
                      errors={{
                        short_opportunity_summary:
                          !!errors && errors.short_opportunity_summary
                            ? errors.short_opportunity_summary
                            : null,
                      }}
                      touched={{
                        short_opportunity_summary: !!touched && !!touched.short_opportunity_summary,
                      }}
                      infoText={`Character ${
                        values?.short_opportunity_summary?.length ?? 0
                      } out of 255`}
                    />
                  </div>
                </Flex>

                <div className='input-label'> Terms</div>
                <Flex classes={['form-flex-direction']} variant='space-between'>
                  {/* Level 1 */}
                  <div className='field-container'>
                    <Input
                      name='terms-level1'
                      placeholder={'Level 1 Header'}
                      type='text'
                      required
                      value={values?.terms?.level1?.header}
                      handleChange={(e) => setFieldValue('terms.level1.header', e.target.value)}
                      dirty={dirty}
                      errors={{
                        terms:
                          !!errors && errors.terms?.level1?.header
                            ? errors.terms?.level1?.header
                            : null,
                      }}
                      touched={{
                        terms: !!touched && !!touched?.terms,
                      }}
                    />
                    <FieldArray
                      name='terms.level1.termObjects'
                      render={(arrayHelpers) => (
                        <>
                          {values?.terms?.level1?.termObjects?.map((factor: any, idx: number) => (
                            <Flex variant='align-center' key={idx}>
                              {/* Term object title */}
                              <Input
                                name='level-termobject-title'
                                placeholder={`Term Object ${idx + 1} Title`}
                                key={`title-${idx}`}
                                type='text'
                                required
                                value={values.terms?.level1?.termObjects?.[idx]?.title}
                                handleChange={(e) =>
                                  setFieldValue(
                                    `terms.level1.termObjects[${idx}].title`,
                                    e.target.value,
                                  )
                                }
                                dirty={dirty}
                                errors={{
                                  terms:
                                    !!errors && errors.terms?.level1?.termObjects?.[idx].toString()
                                      ? errors.terms?.level1?.termObjects?.[idx].toString()
                                      : null,
                                }}
                                touched={{
                                  terms: !!touched && !!touched?.terms,
                                }}
                              />
                              {/* Term object content */}
                              <Input
                                name='level-termobject-content'
                                placeholder={`Term Object ${idx + 1} Content`}
                                classes={['ml-16']}
                                key={`content-${idx}`}
                                type='text'
                                required
                                value={values.terms?.level1?.termObjects?.[idx]?.content}
                                handleChange={(e) =>
                                  setFieldValue(
                                    `terms.level1.termObjects[${idx}].content`,
                                    e.target.value,
                                  )
                                }
                                dirty={dirty}
                                errors={{
                                  terms:
                                    !!errors && errors.terms?.level1?.termObjects?.[idx].toString()
                                      ? errors.terms?.level1?.termObjects?.[idx].toString()
                                      : null,
                                }}
                                touched={{
                                  terms: !!touched && !!touched?.terms,
                                }}
                              />

                              {/* Level 1 Term Objects Button section */}
                              {idx === values?.terms?.level1?.termObjects?.length - 1 && (
                                <>
                                  <Button
                                    classes={['auto-btn-width', 'ml-4']}
                                    variant={'primary-btn'}
                                    text='+'
                                    handleClick={() => {
                                      arrayHelpers.push({
                                        title: '',
                                        content: '',
                                      })
                                    }}
                                  />
                                  {idx > 0 && (
                                    <Button
                                      classes={['auto-btn-width', 'ml-4']}
                                      variant={'primary-btn'}
                                      IconComponent={<DeleteIcon classes={['button-icon']} />}
                                      handleClick={() => {
                                        arrayHelpers.pop()
                                      }}
                                    />
                                  )}
                                </>
                              )}
                            </Flex>
                          ))}
                        </>
                      )}
                    />
                  </div>

                  {/* Level 2 */}
                  <div className='field-container'>
                    <Input
                      name='terms-level2'
                      placeholder={'Level 2 Header'}
                      type='text'
                      required
                      value={values?.terms?.level2?.header}
                      handleChange={(e) => setFieldValue('terms.level2.header', e.target.value)}
                      dirty={dirty}
                      errors={{
                        terms:
                          !!errors && errors.terms?.level2?.header
                            ? errors.terms?.level2?.header
                            : null,
                      }}
                      touched={{
                        terms: !!touched && !!touched?.terms,
                      }}
                    />
                    <FieldArray
                      name='terms.level2.termObjects'
                      render={(arrayHelpers) => (
                        <>
                          {values?.terms?.level2?.termObjects?.map((factor: any, idx: number) => (
                            <Flex variant='align-center' key={idx}>
                              {/* Term object title */}
                              <Input
                                name='level-termobject-title'
                                placeholder={`Term Object ${idx + 1} Title`}
                                key={`title-${idx}`}
                                type='text'
                                required
                                value={values.terms?.level2?.termObjects?.[idx]?.title}
                                handleChange={(e) =>
                                  setFieldValue(
                                    `terms.level2.termObjects[${idx}].title`,
                                    e.target.value,
                                  )
                                }
                                dirty={dirty}
                                errors={{
                                  terms:
                                    !!errors && errors.terms?.level2?.termObjects?.[idx].toString()
                                      ? errors.terms?.level2?.termObjects?.[idx].toString()
                                      : null,
                                }}
                                touched={{
                                  terms: !!touched && !!touched?.terms,
                                }}
                              />
                              {/* Term object content */}
                              <Input
                                name='level-termobject-content'
                                placeholder={`Term Object ${idx + 1} Content`}
                                classes={['ml-16']}
                                key={`content-${idx}`}
                                type='text'
                                required
                                value={values.terms?.level2?.termObjects?.[idx]?.content}
                                handleChange={(e) =>
                                  setFieldValue(
                                    `terms.level2.termObjects[${idx}].content`,
                                    e.target.value,
                                  )
                                }
                                dirty={dirty}
                                errors={{
                                  terms:
                                    !!errors && errors.terms?.level2?.termObjects?.[idx].toString()
                                      ? errors.terms?.level2?.termObjects?.[idx].toString()
                                      : null,
                                }}
                                touched={{
                                  terms: !!touched && !!touched?.terms,
                                }}
                              />

                              {/* Level 2 Term Objects Button section */}
                              {idx === values?.terms?.level2?.termObjects?.length - 1 && (
                                <>
                                  <Button
                                    classes={['auto-btn-width', 'ml-4']}
                                    variant={'primary-btn'}
                                    text='+'
                                    handleClick={() => {
                                      arrayHelpers.push({
                                        title: '',
                                        content: '',
                                      })
                                    }}
                                  />
                                  {idx > 0 && (
                                    <Button
                                      classes={['auto-btn-width', 'ml-4']}
                                      variant={'primary-btn'}
                                      IconComponent={<DeleteIcon classes={['button-icon']} />}
                                      handleClick={() => {
                                        arrayHelpers.pop()
                                      }}
                                    />
                                  )}
                                </>
                              )}
                            </Flex>
                          ))}
                        </>
                      )}
                    />
                  </div>
                </Flex>

                <Flex variant='space-between' classes={['mt-12', 'form-flex-direction']}>
                  <div className='field-container'>
                    <CheckboxInput
                      name='isFeatured'
                      labelText='Featured opportunity'
                      value={values.is_featured ? 'true' : 'false'}
                      handleChange={(val) => setFieldValue('is_featured', val.target.checked)}
                    />
                  </div>
                </Flex>
                <Line />

                <H6 classes={['mt-28', 'mb-32']} text='Company Details' />
                <Line />
                <Flex classes={['form-flex-direction']} variant='space-between'>
                  <div className='field-container'>
                    <TextboxInput
                      name='companyDetails'
                      labelText='Company Details'
                      required
                      value={values.metadata.company.about}
                      handleChange={(e) => setFieldValue('metadata.company.about', e.target.value)}
                      dirty={dirty}
                      errors={{
                        companyDetails:
                          !!errors && errors.metadata?.company?.about
                            ? errors.metadata?.company?.about
                            : null,
                      }}
                      touched={{
                        companyDetails: !!touched && !!touched.metadata?.company?.about,
                      }}
                    />
                  </div>
                </Flex>
                <Flex classes={['form-flex-direction']} variant='space-between'>
                  <div className='field-container'>
                    <div className='input-label'> Key Factors</div>
                    <FieldArray
                      name='key_factors'
                      render={(arrayHelpers) => (
                        <>
                          {values?.key_factors?.map((factor: string, idx: number) => (
                            <Flex variant='align-center' key={idx}>
                              <Input
                                name='key_factors'
                                placeholder={`Key Factor ${idx + 1}`}
                                key={idx}
                                type='text'
                                required
                                value={values.key_factors[idx]}
                                handleChange={(e) =>
                                  setFieldValue(`key_factors[${idx}]`, e.target.value)
                                }
                                dirty={dirty}
                                errors={{
                                  key_factors:
                                    !!errors && errors.key_factors?.[idx].toString()
                                      ? errors.key_factors?.[idx].toString()
                                      : null,
                                }}
                                touched={{
                                  key_factors: !!touched && !!touched?.key_factors,
                                }}
                              />

                              {/* Key factors Button section */}
                              {idx === values?.key_factors.length - 1 && (
                                <>
                                  <Button
                                    classes={['auto-btn-width', 'ml-4']}
                                    variant={'primary-btn'}
                                    text='+'
                                    handleClick={() => {
                                      arrayHelpers.push('')
                                    }}
                                  />
                                  {idx > 0 && (
                                    <Button
                                      classes={['auto-btn-width', 'ml-4']}
                                      variant={'primary-btn'}
                                      IconComponent={<DeleteIcon classes={['button-icon']} />}
                                      handleClick={() => {
                                        arrayHelpers.pop()
                                      }}
                                    />
                                  )}
                                </>
                              )}
                            </Flex>
                          ))}
                        </>
                      )}
                    />
                  </div>
                </Flex>
                <Flex classes={['form-flex-direction', 'mt-8']} variant='space-between'>
                  <div className='field-container'>
                    <div className='input-label'> Highlights</div>
                    <FieldArray
                      name='highlights'
                      render={(arrayHelpers) => (
                        <>
                          {values?.highlights?.map((highlight: string, idx: number) => (
                            <Flex variant='align-center' key={idx}>
                              <Input
                                name='highlights'
                                placeholder={`Highlight ${idx + 1}`}
                                key={idx}
                                type='text'
                                required
                                value={values.highlights[idx]}
                                handleChange={(e) =>
                                  setFieldValue(`highlights[${idx}]`, e.target.value)
                                }
                                dirty={dirty}
                                errors={{
                                  highlights:
                                    !!errors && errors.highlights?.[idx].toString()
                                      ? errors.highlights?.[idx].toString()
                                      : null,
                                }}
                                touched={{
                                  highlights: !!touched && !!touched?.highlights,
                                }}
                              />

                              {/* Highlights Button section */}
                              {idx === values?.highlights?.length - 1 && (
                                <>
                                  <Button
                                    classes={['auto-btn-width', 'ml-4']}
                                    variant={'primary-btn'}
                                    text='+'
                                    handleClick={() => {
                                      arrayHelpers.push('')
                                    }}
                                  />
                                  {idx > 0 && (
                                    <Button
                                      classes={['auto-btn-width', 'ml-4']}
                                      variant={'primary-btn'}
                                      IconComponent={<DeleteIcon classes={['button-icon']} />}
                                      handleClick={() => {
                                        arrayHelpers.pop()
                                      }}
                                    />
                                  )}
                                </>
                              )}
                            </Flex>
                          ))}
                        </>
                      )}
                    />
                  </div>
                </Flex>

                <H6 classes={['mt-28', 'mb-32']} text='Opportunity Uploads' />
                <Line />

                <div className='field-container'>
                  <InputFile
                    name='video'
                    handleFiles={async (file: any) => {
                      if (handleFileUpload && file) {
                        const data = await handleFileUpload(file as File, 'video')
                        if (data && data.data) {
                          setFieldValue('video', data.data.key)
                        } else handleErrorMessage && handleErrorMessage(data.message)
                      } else {
                        setFieldValue('video', '')
                      }
                    }}
                    {...(Object.keys(uploadFilesMetaData.video).length > 0
                      ? { file: uploadFilesMetaData.video }
                      : {})}
                    label='Opportunity Video URL'
                    dirty={dirty}
                    errors={{
                      video: !!errors && errors.video ? errors.video : null,
                    }}
                    touched={{
                      video: !!touched && !!touched.video,
                    }}
                    handleDeleteFile={() => {
                      setFieldValue('video', '')
                    }}
                    type='video'
                  />
                </div>

                <Flex classes={['form-image-flex-direction', 'mt-40']} variant='space-between'>
                  <Flex variant='column-center'>
                    <UploadedImage published fileUrl={signedImageUrl.cover_image} id={'1'} />
                    <InputFile
                      name='projectCover'
                      handleFiles={async (file: any) => {
                        if (handleFileUpload && file) {
                          const data = await handleFileUpload(file as File, 'image')
                          if (data && data.data) {
                            setFieldValue('cover_image', data.data.key)
                            const { url } = await generatePreview(data.data.key)
                            setSignedImageUrl({ ...signedImageUrl, cover_image: url })
                          } else handleErrorMessage && handleErrorMessage(data.message)
                        } else {
                          setFieldValue('cover_image', '')
                          setSignedImageUrl({ ...signedImageUrl, cover_image: '' })
                        }
                      }}
                      {...(Object.keys(uploadFilesMetaData.cover_image).length > 0
                        ? { file: uploadFilesMetaData.cover_image }
                        : {})}
                      label='Opportunity Cover *'
                      dirty={dirty}
                      errors={{
                        projectCover: !!errors && errors.cover_image ? errors.cover_image : null,
                      }}
                      touched={{
                        projectCover: !!touched && !!touched.cover_image,
                      }}
                      handleDeleteFile={() => {
                        setFieldValue('cover_image', '')
                        setSignedImageUrl({ ...signedImageUrl, cover_image: '' })
                      }}
                    />
                  </Flex>
                </Flex>
                <H6 classes={['mt-28', 'mb-32']} text='Opportunity Images' />
                <Flex classes={['form-image-flex-direction', 'mt-40']} variant='space-between'>
                  <FieldArray
                    name='images'
                    render={(arrayHelpers) => (
                      <>
                        {values?.images?.map((image: string, idx: number) => (
                          <Flex variant='column-center' classes={['mb-24']} key={idx}>
                            <UploadedImage
                              published
                              fileUrl={signedImageUrl.images[idx]}
                              id={idx.toString()}
                            />
                            <InputFile
                              name={`opportunityImg${idx + 1}`}
                              handleFiles={async (file: any) => {
                                if (handleFileUpload && file) {
                                  const data = await handleFileUpload(file as File, 'image')
                                  if (data && data.data) {
                                    arrayHelpers.replace(idx, data.data.key)

                                    const { url } = await generatePreview(data.data.key)
                                    const newImages = [...signedImageUrl.images]
                                    newImages[idx] = url
                                    setSignedImageUrl({ ...signedImageUrl, images: newImages })
                                  } else handleErrorMessage && handleErrorMessage(data.message)
                                } else {
                                  // setFieldValue('images', values.images)
                                }
                              }}
                              file={uploadFilesMetaData.images[idx]}
                              label={`Opportunity Image ${idx + 1}`}
                              dirty={dirty}
                              errors={{
                                keyInvestorsImg3:
                                  !!errors && errors.images?.[idx] ? errors.images?.[idx] : null,
                              }}
                              handleDeleteFile={() => {
                                arrayHelpers.replace(idx, '')
                                const newImages = [...signedImageUrl.images]
                                newImages[idx] = ''
                                setSignedImageUrl({ ...signedImageUrl, images: newImages })
                              }}
                            />
                          </Flex>
                        ))}

                        {/* Images Button section*/}
                        <Flex variant='align-center' classes={['mt-16']}>
                          <Button
                            classes={['auto-btn-width']}
                            variant={'primary-btn'}
                            text='Add 1 more image'
                            handleClick={() => {
                              arrayHelpers.push('')
                            }}
                          />
                          {values.images && values?.images?.length > 1 && (
                            <Button
                              classes={['auto-btn-width', 'ml-8']}
                              variant={'primary-btn'}
                              text='Remove last image'
                              handleClick={() => {
                                arrayHelpers.pop()
                              }}
                            />
                          )}
                        </Flex>
                      </>
                    )}
                  />
                </Flex>
                <H6 classes={['mt-28', 'mb-32']} text='Opportunity Documents' />
                <Flex classes={['form-image-flex-direction', 'mt-40']} variant='space-between'>
                  <FieldArray
                    name='documents'
                    render={(arrayHelpers) => (
                      <>
                        {values?.documents?.map((doc: object, idx: number) => (
                          <Flex variant='column' classes={['mb-24']} key={idx}>
                            <div className='input-label'>Document {idx + 1}</div>
                            <Input
                              name='documents'
                              placeholder={`Document ${idx + 1} Title`}
                              key={idx}
                              type='text'
                              required
                              value={values.documents?.[idx]?.title}
                              handleChange={(e) =>
                                setFieldValue(`documents[${idx}].title`, e.target.value)
                              }
                              dirty={dirty}
                              errors={{
                                documents:
                                  !!errors &&
                                  errors?.documents?.[idx] &&
                                  Object.keys(errors.documents?.[idx]).includes('url')
                                    ? getError(errors.documents?.[idx], 'documents')
                                    : null,
                              }}
                              touched={{
                                documents: !!touched && !!touched?.documents,
                              }}
                            />
                            <InputFile
                              name={`opportunityDoc${idx + 1}`}
                              handleFiles={async (file: any) => {
                                if (handleFileUpload && file) {
                                  const data = await handleFileUpload(file as File, 'doc')
                                  if (data && data.data) {
                                    setFieldValue(`documents[${idx}]`, {
                                      title: values.documents?.[idx]?.title ?? '',
                                      url: data.data.key,
                                    })
                                  } else handleErrorMessage && handleErrorMessage(data.message)
                                }
                              }}
                              file={uploadFilesMetaData.documents[idx]}
                              label={''}
                              dirty={dirty}
                              errors={{
                                documents:
                                  !!errors && errors.documents?.[idx]?.toString()
                                    ? errors.documents?.[idx].toString()
                                    : null,
                              }}
                              handleDeleteFile={() => {
                                arrayHelpers.replace(idx, {
                                  title: '',
                                  url: '',
                                })
                              }}
                              type='doc'
                            />
                          </Flex>
                        ))}
                        {/* Images Button section*/}
                        <Flex variant='align-center' classes={['mt-16']}>
                          <Button
                            classes={['auto-btn-width']}
                            variant={'primary-btn'}
                            text='Add 1 more document'
                            handleClick={() => {
                              arrayHelpers.push({
                                title: '',
                                url: '',
                              })
                            }}
                          />
                          {values?.documents?.length > 1 && (
                            <Button
                              classes={['auto-btn-width', 'ml-8']}
                              variant={'primary-btn'}
                              text='Remove last document'
                              handleClick={() => {
                                arrayHelpers.pop()
                              }}
                            />
                          )}
                        </Flex>
                      </>
                    )}
                  />
                </Flex>

                <Flex classes={['toggle-container']}>
                  <div className='input-label'>
                    {values.status === 'PUBLISHED' ? 'Publish' : 'Unpublish'}{' '}
                  </div>
                  <Toggle
                    checked={values.status === 'PUBLISHED'}
                    isDisable={false}
                    handleChange={() =>
                      setFieldValue(
                        'status',
                        values.status === 'PUBLISHED' ? 'UNPUBLISHED' : 'PUBLISHED',
                      )
                    }
                  />
                  <div className='publish-warning-sub-heading mb-20'>
                    <p>
                      Before publishing the opportunity, Please check the mandatory fields are
                      filled in the form. The mandatory fields are Opportunity Name, Asset Class,
                      IRR, Location, Ticket Size, Closing Date, Valuation, Overall Raise Goal, Raise
                      Goal, Legal Documents, Opportunity Summary, Company Name, Company Website,
                      Company Details, Company Logo, Opportunity Cover, Subscription Agreement.
                    </p>
                  </div>
                </Flex>
                <Line />
                <Flex variant='flex-end'>
                  <div className='project-form-save-button-wrapper'>
                    <Flex>
                      <Button
                        classes={['auto-btn-width']}
                        variant={'primary-btn'}
                        text='Save'
                        handleClick={handleSubmit}
                      />
                    </Flex>
                    <div className='project-form-error-message-wrapper'>
                      {Object.entries(errors).map((data, index) => (
                        <span className='error-basic-label' key={index}>
                          {getError(data[1], data[0])}
                        </span>
                      ))}
                    </div>
                  </div>
                </Flex>
              </form>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export { ProjectFormPresenter }
