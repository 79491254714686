import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { investmentServices, investorServices, kycServices, projectServices } from '../../services'
import { useDispatch } from 'react-redux'
import { getFileKeyFromSignedUrl } from '../../lib/utils/helpers'
import { showBanner } from '../../global-state/banner-slice'
import { InvestmentDetailPresenter } from './investment-detail-presenter'
import { KYCDetailsWithDocument } from '../../lib/types'

const InvestmentDetailContainer = () => {
  const dispatch = useDispatch()
  const { projectId, investmentId, investorId } = useParams()
  const [project, setProject] = useState([])
  const [investmentDetail, setInvestmentDetail] = useState<any>([])
  const [kycData, setKycData] = useState<KYCDetailsWithDocument>()
  const [investorProfile, setInvestorProfile] = useState()
  const [statuses, setStatuses] = useState([])

  useEffect(() => {
    if (projectId && investmentId) {
      fetchInvestmentDetail()
    }
  }, [investmentId, projectId])

  const fetchInvestmentDetail = async () => {
    investmentServices.getInvestmentById(investmentId as string).then((response: any) => {
      if (response && response.data && response.status === 200) {
        const opportunityData = {
          opportunity_title: response.data.opportunity_title,
          asset_class: response.data.asset_class,
          ticket_size: response.data.ticket_size,
          investment_return: response.data.investment_return,
          raise_goal: response.data.raise_goal,
        }
        setInvestmentDetail(response.data)
        setProject((prev) => ({ ...prev, ...opportunityData }))

        investorServices.getInvestorProfile(response.data.investor_id).then((response: any) => {
          setInvestorProfile(response)
        })
      }
    })
  }

  useEffect(() => {
    const getKYC = async () => {
      const response = await kycServices.getDocumentWithKYC(String(investorId))
      if (!!response && response.data) {
        setKycData(response.data ?? {})
      }
    }

    getKYC()
  }, [])

  const handleFilePreview = async (fileKey: string) => {
    fileKey = (fileKey as any).replaceAll('/', '%2F')

    const signedUrl = getFileKeyFromSignedUrl(fileKey)
    const data = await projectServices.previewFile(signedUrl)
    if (data && data.data && data.status === 200) return data.data.url
    else {
      dispatch(
        showBanner({
          text: (data.data && data.data.message) || 'Something went wrong preview document',
          variant: 'error-banner',
        }),
      )
      return ''
    }
  }

  const handleUpdateStatus = async (payload: any) => {
    const response = await investmentServices.updateInvestmentStatus(payload)
    if (response.data && response.status === 200) {
      dispatch(
        showBanner({
          text: response.data && response.data.message,
          variant: 'success-banner',
        }),
      )
      await fetchInvestmentDetail()
    } else
      dispatch(
        showBanner({
          text: (response.data && response.data.message) || 'Something went wrong preview document',
          variant: 'error-banner',
        }),
      )
  }

  const handleStatus = async (value: string, field: string, reason: string) => {
    let payload = { investmentId: investmentId, [field]: value }
    if (field === 'investmentStatus' && value === 'APPROVED') {
      payload = {
        ...payload,
        paymentStatus: 'PAYMENT_APPROVED',
      }
    } else if (field === 'investmentStatus' && (value === 'CLOSED' || value === 'REJECTED')) {
      payload = {
        ...payload,
        paymentStatus: 'PAYMENT_REJECTED',
        reason: reason,
      }
    }
    await handleUpdateStatus(payload)
  }

  return (
    <InvestmentDetailPresenter
      project={project}
      kycData={kycData}
      investorProfile={investorProfile}
      statuses={statuses}
      investmentDetail={investmentDetail}
      handleFilePreview={handleFilePreview}
      handleStatus={handleStatus}
    />
  )
}

export { InvestmentDetailContainer }
