import { InvestorData, Role } from './propsTypes'

export type AccountStatus = 'ACTIVE' | 'INACTIVE' | 'DORMANT'

export type KYCStatus =
  | 'NOT_STARTED'
  | 'INITIATED'
  | 'PENDING'
  | 'REJECTED'
  | 'APPROVED'
  | 'RESUBMIT'

export const KycStatus: Record<KYCStatus, KYCStatus> = {
  NOT_STARTED: 'NOT_STARTED',
  INITIATED: 'INITIATED',
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  RESUBMIT: 'RESUBMIT',
}

export const accountStatusMap: Record<AccountStatus, string> = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DORMANT: 'DORMANT',
}

export interface KYCDetails {
  created_at: string
  updated_at: string
  investor_id: number
  is_nda_accepted: boolean
  status: KYCStatus
  metadata: any
  investor_kyc_id: string
  investment_essentials_agreement: boolean
  accept_tob: boolean
  investor_portfolio_id: string
  transaction_checklist_agreement: boolean
  kyc_approval_date?: string
}

export interface KYCDetailsWithDocument extends KYCDetails {
  agreement_document_id: string
}

export interface UserInvestmentDetails {
  canInvest: string
  investmentLimit: string
  kycStatus: string
  totalInvestment: string
}

// profile

export interface ProfileDetailsPresenterProps {
  investorState: InvestorData
  investmentDetail: UserInvestmentDetails
  kycData: KYCDetailsWithDocument
  handleInvestmentLimit: (val: number) => void
  handleSendVerificationEmailLink: () => void
  handleBlockUnblock: (status: Exclude<AccountStatus, 'DORMANT'>) => void
}

export interface InvestorKYCProps {
  kycData: KYCDetails
  investorState: InvestorData
  investmentDetail: UserInvestmentDetails
}

// orders

export interface Order {
  order_id: string
  opportunity_title: string
  order_creation_date: string | null
  investor_portfolio_id: string
  investor_name: string
  investor_email: string
  investment_amount: number
  total_amount_to_be_transferred: number
  investment_completed: boolean
  opportunity_slug: string
  opportunity_id: number
  investor_id: number
  investment_id: number
  completed_steps: string
  unit_allocation_date: string | null
  investor_account_status: AccountStatus
  welcome_letter_link: string
  agreement_document_id: string
  investor_kyc_id: string
  payment_copy: string
  transaction_reference_number: string
}

export interface OrdersPresenterProps {
  pagination: {
    total: number
    limit: number
    count: number
    offset: number
  }
  handleCurrentPage: (currentPage: number) => void
  currentPage: number
  orders: Array<Order>
  resetSearchParams: () => void
  searchParams: {
    limit: string
    offset: string
    keyword: string
    filterBy: string
    sortBy: string
  }
  handleSearchParams: ({ name, value }: { name: string; value: string }) => void
  handleInvestmentClick: (
    opportunitySlug: string,
    opportunityId: number | string,
    investorId: number | string,
    investmentId: number | string,
  ) => void
}

export interface OrderCardProps {
  order_id: string
  opportunity_title: string
  order_creation_date: string | null
  investor_portfolio_id: string
  investor_name: string
  investor_email: string
  currency_code?: string
  investment_amount: number
  total_amount_to_be_transferred: number
  investment_completed: boolean
  completed_steps: string
  unit_allocation_date: string | null
  investor_account_status: AccountStatus
  welcome_letter_link: string
  agreement_document_id: string
  investor_kyc_id: string
  payment_copy: string
  transaction_reference_number: string
  handleInvestmentClick: () => void
}

// investors

export interface InvestorPropsV1 {
  account_status: AccountStatus
  created_at?: string
  email: string
  investment_limit: string
  investor_id: number
  investor_kyc_id?: string
  is_nda_accepted?: boolean
  is_verified?: boolean
  kycId?: number
  metadata?: any
  name: string
  role?: Role
  status?: KYCStatus
  total_invested_amount?: any
  total_investment?: string
  updated_at?: string
  investor_reference: string
}
