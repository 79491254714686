/* eslint-disable quotes */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react'
import {
  Button,
  Flex,
  H2,
  InputModal,
  Span,
  SelectDropdown,
  Line,
  EyeIcon,
  Card,
  P,
  Close,
  H4,
  Input,
  PendingIcon,
  H5,
  CheckCircleIcon,
  ReportIcon,
  ToolTip,
} from '../../lib/components'
import './investment-detail.scss'
import { useBreakpoint } from '../../lib/utils/hooks'
import { XL } from '../../lib/utils/constants'
import {
  displayCurrency,
  displayDollar,
  formatPercentage,
  formatStatus,
} from '../../lib/utils/helpers'
import { InvestmentStatus } from '../../lib/utils/message'
import { accountStatusMap, KycStatus } from '../../lib/types'
import { documentServices, kycServices } from '../../services'
import { CircularProgress } from '@mui/material'

const InvestmentDetailPresenter = ({
  project,
  kycData,
  investorProfile,
  statuses,
  investmentDetail,
  handleFilePreview,
  handleStatus,
}: any) => {
  const { breakpoint } = useBreakpoint()
  const [displayInvestmentStatusModal, setDisplayInvestmentStatusModal] = useState(false)
  const [isAgreementLoading, setIsAgreementLoading] = useState(false)
  const [investmentStatusValue, setInvestmentStatusValue] = useState(
    investmentDetail.investmentStatus,
  )
  const [reason, setReason] = useState<string>('')

  const getTotalAmount = (...amounts: (string | number)[]): string => {
    return amounts.reduce((acc: number, b: string | number) => acc + Number(b ?? 0), 0).toString()
  }

  const getInvestmentStatuses = () => {
    const filteredList = InvestmentStatus.filter((item) => item.value !== 'APPROVED')
    const isPaymentProofUploaded = !!(
      investmentDetail &&
      investmentDetail?.metadata &&
      (investmentDetail?.metadata?.paymentCopy || investmentDetail?.metadata?.transactionNumber)
    )

    const reasonForDisabling = !isPaymentProofUploaded
      ? '(SWIFT copy and transaction ref not uploaded)'
      : ''

    return [
      ...filteredList,
      {
        text: `Approved ${reasonForDisabling}`,
        value: 'APPROVED',
        disabled: !isPaymentProofUploaded,
      },
    ]
  }

  const getKYCReportLink = async (investorKycId: string) => {
    const response = await kycServices.generateKYCReportLink(investorKycId)

    if (response.status === 200 && response.data) {
      return response.data
    }
  }

  const getDocumentDownloadLink = async (documentId: string) => {
    const response = await documentServices.getDocumentDownloadLink(documentId)

    if (response.status === 200 && response.data) {
      return response.data
    }
  }

  const handleGenerateKYCReportBtnClick = async (kycId: string) => {
    try {
      const { reportLink } = await getKYCReportLink(kycId)
      window.open(reportLink)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDownloadAgreementBtnClick = async (documentId: string) => {
    try {
      if (!documentId) return

      setIsAgreementLoading(true)
      const agreementLink = await getDocumentDownloadLink(documentId)
      setIsAgreementLoading(false)
      window.open(agreementLink)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Flex variant='column' classes={['investment-detail-wrapper']}>
      <H2
        text='Investment Detail'
        classes={['align-center', 'investment-detail-wrapper-heading']}
      />

      {investorProfile && investorProfile?.accountStatus === accountStatusMap.DORMANT && (
        <div className='warning-container-fit-content  mb-24'>
          <PendingIcon classes={['']} width='20px' height='20px' />
          This user is dormant
        </div>
      )}

      <Flex variant={breakpoint && breakpoint < XL ? 'column' : 'row'}>
        {project && project.opportunity_title && (
          <Card classes={['p-40', 'mb-48', 'mr-16']}>
            <P text='Opportunity Details' classes={['mb-16']} />
            <Flex variant='space-between'>
              <Span variant='bold' text='Opportunity Name' />
              <Span variant='thin' text={(project && project.opportunity_title) ?? ''} />
            </Flex>
            <Flex variant='space-between' classes={['mt-4']}>
              <Span variant='bold' text='Asset class' />
              <Span variant='thin' text={(project && project.asset_class) ?? ''} />
            </Flex>
            <Flex variant='space-between' classes={['mt-4']}>
              <Span variant='bold' text='Ticket Size' />
              <Span variant='thin' text={displayCurrency((project && project.ticket_size) ?? 0)} />
            </Flex>
            <Flex variant='space-between' classes={['mt-4']}>
              <Span variant='bold' text='IRR' />
              <Span
                variant='thin'
                text={formatPercentage((project && project.investment_return) ?? 0)}
              />
            </Flex>
            <Flex variant='space-between' classes={['mt-4']}>
              <Span variant='bold' text='Raise Goal' />
              <Span variant='thin' text={displayCurrency((project && project.raise_goal) ?? 0)} />
            </Flex>
          </Card>
        )}

        {investorProfile && investorProfile.email && (
          <Card classes={['p-40', 'mb-48', 'mr-16']}>
            <P text='Investor Details' classes={['mb-16']} />
            <Flex variant='space-between' classes={['mt-4']}>
              <Span variant='bold' text='Name' />
              <Span variant='thin' text={(investorProfile && investorProfile.name) ?? ''} />
            </Flex>
            <Flex variant='space-between' classes={['mt-4']}>
              <Span variant='bold' text='Email' />
              <Span variant='thin' text={(investorProfile && investorProfile.email) ?? ''} />
            </Flex>
            <Flex variant='space-between' classes={['mt-4']}>
              <Span variant='bold' text='Phone Number' />
              <Span variant='thin' text={(investorProfile && investorProfile.phoneNumber) ?? ''} />
            </Flex>
            <Flex variant='space-between' classes={['mt-4']}>
              <Span variant='bold' text='Country of Residence' />
              <Span
                variant='thin'
                text={(investorProfile && investorProfile.countryOfResidence) ?? ''}
              />
            </Flex>
            <Flex variant='space-between' classes={['mt-4']}>
              <Span variant='bold' text='Investor ID' />
              <Span
                variant='thin'
                text={(investorProfile && investorProfile?.investorReference) ?? ''}
              />
            </Flex>
            <Flex variant='space-between' classes={['mt-4']}>
              <Span variant='bold' text='Portfolio ID' />
              <Span variant='thin' text={(kycData && kycData.investor_portfolio_id) ?? ''} />
            </Flex>
            {kycData && kycData?.metadata?.welcomeLetter && (
              <Flex variant='space-between' classes={['mt-4']}>
                <Span variant='bold' text='Welcome Letter' />
                <EyeIcon
                  classes={['align-center', 'pointer', 'mt-4']}
                  height='24px'
                  width='24px'
                  onClick={async () => {
                    if (kycData && kycData?.metadata?.welcomeLetter) {
                      const url = kycData && kycData?.metadata?.welcomeLetter
                      url && window.open(url, '_blank')
                    }
                  }}
                />
              </Flex>
            )}
          </Card>
        )}
      </Flex>
      <Flex variant={breakpoint && breakpoint < XL ? 'column' : 'row'}>
        {kycData && kycData.status && (
          <Card classes={['p-40', 'mb-48', 'mr-16']}>
            <P text='KYC Details' classes={['mb-16']} />
            <Flex variant='space-between'>
              <Span variant='bold' text='Status' />
              <Span variant='thin' text={(kycData && kycData.status) ?? ''} />
            </Flex>
            <Flex variant='space-between' classes={['mt-4']}>
              <Span variant='bold' text='Investor Type' />
              <Span variant='thin' text={(investorProfile && investorProfile.role) ?? ''} />
            </Flex>
            {kycData && kycData?.id && (
              <Flex variant='space-between' classes={['mt-4']}>
                <Span variant='bold' text='KYC Report' />
                <EyeIcon
                  classes={['align-center', 'pointer', 'mt-4']}
                  height='24px'
                  width='24px'
                  onClick={() => handleGenerateKYCReportBtnClick(kycData?.id)}
                />
              </Flex>
            )}
          </Card>
        )}
        {investmentDetail && (
          <Card classes={['p-40', 'mb-48', 'mr-16']}>
            <P text='Investment Details' classes={['mb-16']} />
            <Flex variant='space-between'>
              <Span variant='bold' text='Order ID' />
              <Span variant='thin' text={(investmentDetail && investmentDetail.order_id) ?? ''} />
            </Flex>
            <Flex variant='space-between' classes={['mt-4']}>
              <Span variant='bold' text='Investment Status' />
              <Span
                variant='thin'
                text={formatStatus((investmentDetail && investmentDetail.status) ?? '')}
              />
            </Flex>

            {kycData && kycData?.agreement_document_id && (
              <Flex variant='space-between' classes={['mt-4']}>
                <Span variant='bold' text='Client Agreement' />

                {!isAgreementLoading ? (
                  <EyeIcon
                    classes={['align-center', 'pointer', 'mt-4']}
                    height='24px'
                    width='24px'
                    onClick={() => handleDownloadAgreementBtnClick(kycData?.agreement_document_id)}
                  />
                ) : (
                  <CircularProgress size={20} />
                )}
              </Flex>
            )}
            {/* Showing swift copy */}
            {investmentDetail && investmentDetail?.metadata?.paymentCopy && (
              <Flex variant='space-between' classes={['mt-4']}>
                <Span variant='bold' text='SWIFT Copy' />
                <EyeIcon
                  classes={['align-center', 'pointer', 'mt-4']}
                  height='24px'
                  width='24px'
                  onClick={async () => {
                    if (investmentDetail && investmentDetail?.metadata?.paymentCopy) {
                      const url = await handleFilePreview(investmentDetail.metadata.paymentCopy)
                      url && window.open(url, '_blank')
                    }
                  }}
                />
              </Flex>
            )}
            {/* Showing transaction number */}
            {investmentDetail &&
              !investmentDetail?.metadata?.paymentCopy &&
              investmentDetail?.metadata?.transactionNumber && (
                <Flex variant='space-between' classes={['mt-4']}>
                  <Span variant='bold' text='Transaction Reference Number' />
                  <Span
                    variant='thin'
                    text={(investmentDetail && investmentDetail?.metadata?.transactionNumber) ?? ''}
                  />
                </Flex>
              )}
            <Flex variant='space-between' classes={['mt-4']}>
              <Span variant='bold' text='Investment Amount' />
              <Span
                variant='thin'
                text={displayDollar(
                  (investmentDetail && investmentDetail.amount) ?? 0,
                  (investmentDetail && investmentDetail.currency_code) ?? 'USD',
                  2,
                  true,
                )}
              />
            </Flex>
            <Flex variant='space-between' classes={['mt-4']}>
              <Span variant='bold' text='Platform Fee' />
              <Span
                variant='thin'
                text={displayDollar(
                  // Todo, make platform_fee * 100 in client and remove it from here
                  (investmentDetail && investmentDetail?.platform_fee?.amount * 100) ?? 0,
                  (investmentDetail && investmentDetail?.platform_fee?.currency_code) ?? 'USD',
                  2,
                  true,
                )}
              />
            </Flex>
            <Flex variant='space-between' classes={['mt-4']}>
              <Span variant='bold' text='Total Amount' />
              <Span
                variant='thin'
                text={displayDollar(
                  getTotalAmount(
                    (investmentDetail && investmentDetail.amount) ?? 0,
                    (investmentDetail && investmentDetail?.platform_fee?.amount * 100) ?? 0,
                  ),
                  (investmentDetail && investmentDetail?.platform_fee?.currency_code) ?? 'USD',
                  2,
                  true,
                )}
              />
            </Flex>
            <Flex variant='space-between' classes={['mt-16']}>
              {displayInvestmentStatusModal ? (
                <InputModal>
                  <Flex variant='column' classes={['input-modal']}>
                    <Flex variant='space-between'>
                      <H4 text={'Update Investment Status'} />
                      <Close
                        height='24px'
                        onClick={() => {
                          setDisplayInvestmentStatusModal(false)
                          setInvestmentStatusValue(investmentDetail.investmentStatus)
                        }}
                      />
                    </Flex>
                    <Line />
                    <Flex variant='column' classes={['reply-section']}>
                      <Span variant='bold' text='Are you sure? You want to update.' />
                      <SelectDropdown
                        name='investmentStatus'
                        id='investmentStatusId'
                        formId=''
                        list={getInvestmentStatuses()}
                        value={investmentStatusValue || investmentDetail.investmentStatus}
                        handleChange={(val) => {
                          setInvestmentStatusValue(val)
                        }}
                      />
                      {investmentStatusValue &&
                        (investmentStatusValue === 'CLOSED' ||
                          investmentStatusValue === 'REJECTED') && (
                          <Input
                            labelText='Reason'
                            type='text'
                            name='reason'
                            placeholder='Enter reason for rejection'
                            value={reason}
                            handleChange={(e) => setReason(e.target.value)}
                          />
                        )}
                      <Button
                        classes={['mt-24']}
                        variant='primary-btn'
                        text='Update'
                        handleClick={() => {
                          handleStatus(investmentStatusValue, 'investmentStatus', reason.trim())
                          setDisplayInvestmentStatusModal(false)
                        }}
                      />
                    </Flex>
                  </Flex>
                </InputModal>
              ) : null}
              <Button
                variant='primary-btn'
                text='Update Investment Status'
                isDisabled={
                  investmentDetail?.status === 'APPROVED' ||
                  investmentDetail?.status === 'REJECTED' ||
                  investmentDetail?.status === 'CLOSED'
                }
                handleClick={() => {
                  setDisplayInvestmentStatusModal(true)
                }}
              />
            </Flex>
          </Card>
        )}
      </Flex>

      <Flex variant={breakpoint && breakpoint < XL ? 'column' : 'row'}>
        <Card classes={['p-40', 'mb-48', 'mr-16']}>
          <H5 text='Investor Important checklist' classes={['mb-16']} />
          <Flex variant='space-between'>
            <Span variant='bold' text='Email Verified' />

            {investorProfile && investorProfile.isEmailVerified ? (
              <CheckCircleIcon classes={['mr-4']} height='23px' />
            ) : (
              <ReportIcon classes={['mr-4']} height='23px' />
            )}
          </Flex>

          <Flex variant='space-between' classes={['mt-4']}>
            <Span variant='bold' text='KYC Complete' />

            {kycData && kycData.status === KycStatus.APPROVED ? (
              <CheckCircleIcon classes={['mr-4']} height='23px' />
            ) : (
              <ToolTip
                content={kycData && kycData.status}
                contentClassNames={['tool-tip-container-fit']}
              >
                <span>
                  <ReportIcon classes={['mr-4']} height='23px' />
                </span>
              </ToolTip>
            )}
          </Flex>

          <Flex variant='space-between' classes={['mt-4']}>
            <Span variant='bold' text='Confidentiality Agreement Accepted' />

            {kycData && kycData.is_nda_accepted ? (
              <CheckCircleIcon classes={['mr-4']} height='23px' />
            ) : (
              <ReportIcon classes={['mr-4']} height='23px' />
            )}
          </Flex>

          <Flex variant='space-between' classes={['mt-4']}>
            <Span variant='bold' text='Risk Checklist Accepted' />

            {kycData && kycData.investment_essentials_agreement ? (
              <CheckCircleIcon classes={['mr-4']} height='23px' />
            ) : (
              <ReportIcon classes={['mr-4']} height='23px' />
            )}
          </Flex>

          <Flex variant='space-between' classes={['mt-4']}>
            <Span variant='bold' text='Terms of Business Accepted' />

            {kycData && kycData.accept_tob ? (
              <CheckCircleIcon classes={['mr-4']} height='23px' />
            ) : (
              <ReportIcon classes={['mr-4']} height='23px' />
            )}
          </Flex>

          <Flex variant='space-between' classes={['mt-4']}>
            <Span variant='bold' text='Transaction Checklist Accepted' />

            {kycData && kycData.transaction_checklist_agreement ? (
              <CheckCircleIcon classes={['mr-4']} height='23px' />
            ) : (
              <ReportIcon classes={['mr-4']} height='23px' />
            )}
          </Flex>

          <Flex variant='space-between' classes={['mt-4']}>
            <Span variant='bold' text='Agreement Signed' />

            {kycData && kycData.agreement_document_id ? (
              <CheckCircleIcon classes={['mr-4']} height='23px' />
            ) : (
              <ReportIcon classes={['mr-4']} height='23px' />
            )}
          </Flex>

          <Flex variant='space-between' classes={['mt-4']}>
            <Span variant='bold' text='Welcome Letter Shared' />

            {kycData && kycData?.metadata?.welcomeLetter ? (
              <CheckCircleIcon classes={['mr-4']} height='23px' />
            ) : (
              <ReportIcon classes={['mr-4']} height='23px' />
            )}
          </Flex>
        </Card>
      </Flex>

      <Flex variant='column' classes={['table_scroll_horizontal']}>
        {statuses && statuses.length > 0 && (
          <>
            <H4 text='Status logs' />
          </>
        )}
        {statuses && statuses.length > 0 && (
          <table className='styled-table'>
            <thead>
              <tr>
                <th>Updated By</th>
                <th>Updated At</th>
                <th>Investment Status</th>
                <th>Payment Status</th>
              </tr>
            </thead>
            <tbody>
              {statuses
                .sort((a: any, b: any) => (a.updatedAt < b.updatedAt ? 1 : -1))
                .map((doc: any) => {
                  return (
                    !!doc.id && (
                      <tr key={doc.updatedAt}>
                        <td>{doc.email}</td>
                        <td>{`${new Date(doc.updatedAt).toLocaleString()}`}</td>
                        <td>{formatStatus(doc.investmentStatus)}</td>
                        <td>{formatStatus(doc.paymentStatus)}</td>
                      </tr>
                    )
                  )
                })}
            </tbody>
          </table>
        )}
      </Flex>
    </Flex>
  )
}

export { InvestmentDetailPresenter }
