import React, { MouseEventHandler, ReactEventHandler } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { FormikProps } from 'formik'
import { store } from '../../store'
import {
  signInSchema,
  forgotPasswordSchema,
  emailInputSchema,
  projectSchema,
  homeSchema,
  opportunitySchema,
} from '../utils/schema'
import { AccountStatus, KYCStatus } from './propsTypes_v1'
export interface IMessage {
  type: 'success' | 'error' | 'warning' | 'progress'
  data: string
}
export interface InputProps {
  type?: 'text' | 'date' | 'email' | 'file' | 'hidden' | 'number' | 'password' | 'range' | 'search'
  labelText?: string
  name: string
  value: string
  max?: number
  maxLength?: number
  min?: number
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  placeholder?: string
  disabled?: boolean
  message?: IMessage
  errors?: { [key: string]: string | null }
  touched?: { [key: string]: boolean }
  dirty?: boolean
  infoText?: string
  classes?: string[]
}

export interface TextAreaProps {
  labelText?: string
  name: string
  value: string
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  placeholder?: string
  message?: IMessage
  errors?: { [key: string]: string | null }
  touched?: { [key: string]: boolean }
  infoText?: string
  dirty?: boolean
}

export type InputPropsWithFormik<T1 extends FormikProps<T1>> = T1 & InputProps
export interface IStatusProps {
  type: 'success' | 'error' | 'warning' | 'progress' | 'info'
  text: string | null
  variant: 'basic' | 'bold' | 'thin'
  IconComponent?: React.ReactNode
}

export interface IconProps {
  type?:
    | 'error'
    | 'success'
    | 'progress'
    | 'warning'
    | 'default'
    | 'warning-banner'
    | 'error-banner'
    | 'success-banner'
    | 'red'
    | 'default-light'
  height?: string
  onClick?: MouseEventHandler<SVGSVGElement>
  width?: string
  classes?: string[]
}

export interface ButtonProps {
  text?: string
  handleClick: () => void
  variant: 'primary-btn' | 'secondary-btn' | 'basic-btn'
  isDisabled?: boolean
  buttonType?: 'button' | 'submit' | 'reset' | undefined
  IconComponent?: React.ReactNode
  iconVariant?: 'left' | 'right'
  classes?: string[]
}
export interface AnchorProps {
  text: string
  href: string
  variant: 'basic-anchor' | 'label-anchor' | 'nav-link' | 'nav-link-bold'
  isDisabled?: boolean
  IconComponent?: React.ReactNode
  classes?: string[]
}
export interface IHeadingProps {
  text: string
  styled?: object
  classes?: string[]
}

export interface ICardProps {
  children?: React.ReactNode
  styled?: object
  classes?: string[]
}
export interface LoginDetails {
  email: string
  password: string
  rememberMe: string
}
export type projectDetails = {
  id?: string
  projectName: string
  projectSlug: string
  closingDate?: Date
  irr: string
  ticketSize: string
  projectSummary: string
  valuation: string
  companyWebsite: string
  location: string
  raiseGoal: string
  overallRaiseGoal: string
  dealContours: string
  companyDetails: string
  companyMilestones: string
  legalDocuments: string
  isHighCommitenceProject: boolean
  isHideCompanyDetails: boolean
  companyName: string
  projectCover: string
  assetClass: string
  isFeatured: boolean
  isPublished: boolean
  clientEngagement1: string
  clientEngagement2: string
  clientEngagement3: string
  clientEngagement4: string
  keyInvestorsImg1: string
  keyInvestorsImg2: string
  keyInvestorsImg3: string
  keyInvestorsImg4: string
  keyInvestorsImg5: string
  keyInvestorsName1: string
  keyInvestorsName2: string
  keyInvestorsName3: string
  keyInvestorsName4: string
  keyInvestorsName5: string
  companyLogo: string
  currencyCode?: string
  subscriptionAgreement: string
  investmentTimelineStartDate?: Date
  investmentTimelineEndDate?: Date
  shipmentStatus?: string
  expectedShipmentCompletionDate?: Date
  plannedShipmentCompletionDate?: Date
  productVideoURL: string
  productImages: string[]
}

export type homeDetails = {
  keyInvestorsImg1: string
  keyInvestorsImg2: string
  keyInvestorsImg3: string
  keyInvestorsImg4: string
  keyInvestorsImg5: string
  keyInvestorsName1: string
  keyInvestorsName2: string
  keyInvestorsName3: string
  keyInvestorsName4: string
  keyInvestorsName5: string
}

export interface ILoginForm<T> {
  reRef?: React.RefObject<ReCAPTCHA>
  initialValues: T
  validationSchema: typeof signInSchema
  googleLoginHandler?: (value: string) => void
  handleFormSubmit: (value: LoginDetails) => void
}
export interface IFormProps<T1, T2> {
  reRef?: React.RefObject<ReCAPTCHA>
  initialValues: T1
  handleFormSubmit: (value: T2) => void
  validationSchema:
    | typeof signInSchema
    | typeof forgotPasswordSchema
    | typeof emailInputSchema
    | typeof projectSchema
    | typeof homeSchema
    | typeof opportunitySchema
  googleLoginHandler?: (value: object) => void
  handleFileUpload?: (value: File, type: 'image' | 'doc' | 'video') => any
  handleErrorMessage?: (value: string) => void
}
export interface CheckboxProps {
  labelText?: string
  name: string
  value?: string
  checked?: boolean
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  errors?: { [key: string]: string | null }
  touched?: { [key: string]: boolean }
  dirty?: boolean
}

export interface PProps {
  text: string
  variant?: 'center' | 'left' | 'right' | 'default-gray' | 'default-blue' | 'default-red'
  textSize?: 'regular' | 'small'
  classes?: string[]
}

export interface SpanProps {
  text: string
  children?: React.ReactNode
  variant?: 'default' | 'bold' | 'thin' | 'large'
  classes?: string[]
}
export interface FlexProps {
  children?: React.ReactNode
  variant?:
    | 'column-center'
    | 'row-center'
    | 'space-between'
    | 'column'
    | 'row'
    | 'space-evenly'
    | 'flex-end'
    | 'align-center'
  classes?: string[]
  onClick?: ReactEventHandler
}

export interface gbcUserState {
  authToken: string
  email: string
  userId: number
  role: string
  isEmailVerified: boolean
}

export enum Role {
  superUser = 'SUPERUSER',
  investor = 'INVESTOR',
  gbcUser = 'GBC_USER',
}

export interface bannerState {
  show: boolean
  text: string
  variant?: 'warning-banner' | 'error-banner' | 'success-banner'
}
export interface modelState {
  name: string
  isShow: boolean
  isConfirm: boolean
  message: string
}

export type AppDispatch = typeof store.dispatch
export type AppState = typeof store.getState
export type Event = React.ChangeEvent<HTMLInputElement>
export interface VerifyEmailProps {
  email: string
  handleClick: () => void
}

export interface VerifiedEmailProps {
  handleClick: () => void
}

export interface IGoogleReCaptchaProps {
  handleChange: (reCaptchaToken: string | null) => void
  handleExpired?: () => void
  reCaptchaRef?: React.RefObject<ReCAPTCHA>
  size?: 'normal' | 'compact' | 'invisible'
}

export interface GLoginProps {
  clientId?: string
  onSuccess: (res: object) => void
  onFailure: (res: object) => void
  cookiePolicy?: string
  render?: () => React.ReactNode
}
export interface list {
  text: string
  value: string
  disabled?: boolean
}
export interface SelectDropdownProps {
  classes?: string[]
  id?: string
  formId?: string
  name: string
  list: list[]
  handleChange?: (val: string) => void
  errors?: { [key: string]: string | null }
  touched?: { [key: string]: boolean }
  dirty?: boolean
  value?: string
  defaultValue?: string
  placeholder?: string
  required?: boolean
}

export interface InputFileProps {
  label: string
  name: string
  file?: FileDataProps
  type?: 'image' | 'doc' | 'video'
  errors?: { [key: string]: string | null }
  touched?: { [key: string]: boolean }
  dirty?: boolean
  handleFiles: (fileData: FileDataProps | undefined) => void
  handleDeleteFile?: () => void
  infoText?: string
}

export interface MultiInputFileProps {
  label: string
  name: string
  file?: FileDataProps[]
  type?: 'image' | 'doc'
  errors?: { [key: string]: string | null }
  touched?: { [key: string]: boolean }
  dirty?: boolean
  handleFiles: (fileData: FileDataProps[] | undefined) => void
  handleDeleteFile?: () => void
}

export interface FileDataProps {
  name: string
  size?: number
  type: string
  date?: Date
}
export interface ProgressBarProps {
  variant?: 'default-bar' | 'green-bar' | 'red-bar'
  value?: string
  maxValue?: string
}

export interface TagProps {
  variant?: 'white-bg' | 'black-bg'
  text: string
  classes?: string[]
}

export interface ImageProps {
  source?: string
  classes?: string[]
  variant?: 'all' | 'top' | 'left' | 'right' | 'bottom' | 'round'
  radius?: 'r8' | 'r16'
}

export interface ListProps {
  listData?: string[]
  classes?: string[]
}

export interface CTABannerProps {
  classes?: string[]
  heading?: string
  subHeading?: string
  ButtonComponent?: React.ReactNode
}

export interface ProjectCardProps {
  id: number
  projectName: string
  projectSlug: string
  closingDate: string
  irr: string
  ticketSize: string
  projectSummary: string
  valuation: string
  companyWebsite: string
  location: string
  raiseGoal: string
  overallRaiseGoal: string
  dealContours: string[]
  companyDetails: string
  companyMilestones: string[]
  legalDocuments: string
  isHighCommitenceProject: boolean
  isHideCompanyDetails: boolean
  companyName: string
  projectCover: string
  assetClass: string
  isFeatured: boolean
  isPublished: boolean
  clientEngagement1: string
  clientEngagement2: string
  clientEngagement3: string
  clientEngagement4: string
  companyLogo: string
  isSavedForLater: boolean
  noOfInvestors: string
  committedAmount: string
  handleClick?: (projectSlug: string) => void
  createdDate?: string
  updatedAt?: string
  createdAt?: string
  currencyCode?: string
}

export interface LastMinuteListProps {
  projectSlug: string
  projectName: string
  commitedPercentage: string
  projectSummary: string
  projectCover: string
  handleClick: (projectSlug: string) => void
  closingDate: string
}

export interface ProjectDetailProps {
  project: ProjectCardProps
  investHandleClick: () => void
}

export interface DealContoursProps {
  dealContoursListData: string[]
}

export interface AboutCompanyProps {
  aboutCompany: string
  image?: string
}

export interface ProjectDetailPresenterProps {
  project: ProjectCardProps
  investHandleClick: () => void
  viewDocumentHandleClick: (legalDocuments: string) => void
}
export interface CompanyDetailProps {
  project: ProjectCardProps
  investHandleClick: () => void
  viewDocumentHandleClick: (legalDocuments: string) => void
}
export interface ProjectListProps {
  id: number
  projectSlug: string
  projectName: string
  assetClass?: string
  commitedPercentage: string
  isHighCommitenceProject: boolean
  projectSummary: string
  irr: string
  ticketSize: string
  projectCover: string
  handleClick: (projectSlug: string, id: number) => void
  handleProjectDelete: (id: number) => void
  handleProjectInvestmentClick: (projectSlug: string, projectId: number) => void
  closingDate: string
  isPublished: boolean
}

export interface FeaturedProjectProps {
  featuredProject: ProjectCardProps
  handleClick: (projectSlug: string) => void
}
export interface SearchParams {
  keyword?: string
  sortBy?:
    | 'CLOSING_DATE_DESC' // default
    | 'CLOSING_DATE_ASC'
    | 'TICKET_SIZE_DESC'
    | 'TICKET_SIZE_ASC'
    | ''
  filterBy?: string
  offset: string
  limit: string
}

export interface InvestmentSearchParams {
  investor_id?: number | string
  opportunity_id?: number | string
  keyword?: string
  sortBy?:
    | 'CLOSING_DATE_DESC' // default
    | 'CLOSING_DATE_ASC'
    | 'TICKET_SIZE_DESC'
    | 'TICKET_SIZE_ASC'
    | ''
  filterBy?: string
  offset: string
  limit: string
}

export interface InvestorProps {
  createdAt?: string
  updatedAt?: string
  userId: number
  acceptedKYCAccuracy?: boolean
  acceptedTNC?: boolean
  accountStatus: AccountStatus
  acceptedNDA?: boolean
  investorType?: string
  kycStatus: string
  investmentLimit: number
  totalInvestedAmount?: number
  email: string
  role?: Role
  isEmailVerified?: boolean
  userSource?: string
  name: string
  countryOfResidence: string
  countryCode: number
  phoneNumber: number
  citizenship: string
  passportNumber: number
  kycId?: number
  investorReference?: string
}

export interface InvestmentProps extends ProjectCardProps {
  userId: number
  email: string
  role: string
  isEmailVerified: boolean
  userSource: string
  name: string
  countryOfResidence: string
  countryCode?: any
  phoneNumber: string
  citizenship: string
  passportNumber: string
  accountStatus: string
  dob: string
  city: string
  zip: string
  address: any[]
  proofOfAddress: string
  projectId: number
  investmentAmount: number
  investmentStatus: string
  paymentStatus: string
  agreement: string
  fundAllocation?: string
  investorDeclaration: string
  metadata: any
}

export interface InvestorKYCDocuments {
  createdAt: string
  updatedAt: string
  id: number
  userId: number
  acceptedKYCAccuracy: boolean
  acceptedTNC: boolean
  acceptedNDA: boolean
  investorType: string
  trustType: string
  kycStatus: string
  investmentLimit: number
  documents: [any]
}

export interface InvestorData {
  investor: InvestorProps
}

export interface AxiosResponse<T> {
  data: T
  status: number
}

export interface ToggleProps {
  checked: boolean
  classes?: string[]
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  isDisable?: boolean
}

export interface ToolTipProps {
  content: string
  variant?: 'table-cell'
  children: JSX.Element
  contentClassNames?: string[]
}
export interface ToolTipBlackProps {
  classes?: string[]
  variant: 'pending-icon' | 'info-icon' | 'check-circle-icon' | 'report-icon'
  children: JSX.Element | string
}

export interface logsProps {
  kyc: object
  profile: object
  statuses: object
}

export interface investmentDetailProps {
  agreement: string
  createdAt: string
  currencyCode: string
  id: string
  investmentAmount: number
  investmentStatus: string
  investorDeclaration: string
  logs: logsProps
  metadata: { completedSteps: number }
  paymentCopy: string
  paymentStatus: string
  projectId: string
  updatedAt: string
  userId: string
}

export interface QuarterlyReportProps {
  investorId: number
  quater: string
  file: string
}

export interface Jobs {
  jobId: string
  createdAt: string
  fileId: string
  status: string
  email: string
  investmentId: number
}

export interface JobResult {
  email: string
  fileId: string
  status: string
  metadata: any
  reason: string
  investorId: number
}

export interface JobResponse {
  createdAt: string
  updatedAt: string
  jobId: string
  type: string
  payload: {
    projectId: number
    fileIds: string[]
  }
  status: string
  result?: {
    jobResults?: JobResult[]
  }
}

// v1

export type AssetsClass = 'Trade Finance' | 'Fixed Income' | 'Private Equity'
export type PublishingStatus = 'PUBLISHED' | 'UNPUBLISHED'

export const InvestorQueryCategoryType = [
  'Platform',
  'Account',
  'Investments',
  'Verification and KYC',
  'Refunds',
  'Urgent Request',
]

type AssistanceCategoryType = typeof InvestorQueryCategoryType
export interface Queries {
  id: number
  created_at: string
  updated_at: string
  name: string
  email: string
  subject: string
  message: string
  assistance_category?: AssistanceCategoryType
}

export interface OpportunityTerm {
  level1: {
    header: string
    termObjects: Array<{ title: string; content: string }>
  }
  level2: {
    header: string
    termObjects: Array<{ title: string; content: string }>
  }
}

export interface NewOpportunityUpdate {
  opportunity_id: number
  title: string
  description: string
}

export interface OpportunityUpdates extends NewOpportunityUpdate {
  id: string
  created_at: string
}
export interface NewOpportunity {
  [key: string]:
    | string
    | boolean
    | AssetsClass
    | PublishingStatus
    | Array<string>
    | number
    | OpportunityTerm
    | object
    | undefined
  opportunity_title: string
  opportunity_slug: string
  jurisdiction: string
  currency_code: string
  is_featured: boolean
  asset_class: AssetsClass
  status: PublishingStatus
  images?: Array<string>
  video: string
  opportunity_summary: string
  short_opportunity_summary: string
  raise_goal: number
  closing_date: string
  investment_return: number
  ticket_size: number
  documents: {
    title: string
    url: string
  }[]
  key_factors: Array<string>
  terms: OpportunityTerm
  highlights: Array<string>
  metadata: {
    company: {
      website: string
      about: string
    }
  }
  cover_image: string
  coupon_payment_interval_in_months: number
}

export interface Opportunity extends NewOpportunity {
  id: number
  no_of_investors: number
  total_investment_amount: number
  updates: Array<OpportunityUpdates>
}

export interface OpportunityCardProps {
  id: number
  opportunitySlug: string
  opportunityTitle: string
  assetClass: string
  expectedReturn: string
  ticketSize: string
  isPublished: boolean
  committedPercentage: string
  closingDate: string
  handleClick: (opportunity_slug: string, id: number) => void
  handleProjectInvestmentClick: (opportunity_slug: string, opportunity_id: number) => void
}

export interface LastMinuteListPropsV1 {
  opportunitySlug: string
  opportunityTitle: string
  committedPercentage: string
  opportunitySummary: string
  opportunityCover: string
  handleClick: (opportunity_slug: string) => void
  closingDate: string
}

export interface InvestmentSearchParamsV1 {
  userId?: number | string
  opportunity_id?: number | string
  keyword?: string
  sortBy?:
    | 'CLOSING_DATE_DESC' // default
    | 'CLOSING_DATE_ASC'
    | 'TICKET_SIZE_DESC'
    | 'TICKET_SIZE_ASC'
    | ''
  filterBy?: string
  offset: string
  limit: string
}

export interface InvestmentPropsV1 {
  id: number
  investor_id: number
  name: string
  email: string
  status: string
  amount: string
  opportunity_id: number
  opportunity_slug: string
  created_at: string
  currency_code: string
  asset_class: string
  closing_date: string
  ticket_size: number
}
