import { axiosInstance } from '.'

import { GET_DOCUMENT_DOWNLOAD_LINK } from './endpoints'

export const documentServices = {
  getDocumentDownloadLink: async (documentId: string) => {
    try {
      return await axiosInstance.get(GET_DOCUMENT_DOWNLOAD_LINK, {
        params: { documentId },
      })
    } catch (error: any) {
      return error.response
    }
  },
}
