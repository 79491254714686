import { axiosInstance } from '.'
import {
  COMMENT_OR_APPROVE_KYC_DOCUMENT,
  GET_KYC_V1,
  GET_KYC_REPORT_LINK,
  SET_INVESTMENT_LIMIT,
  UPDATE_KYC,
  GET_DOCUMENT_WITH_KYC,
} from './endpoints'

export const kycServices = {
  setInvestmentLimit: async (investorId: number, details: { [key: string]: string | number }) => {
    try {
      const data: any = await axiosInstance.post(SET_INVESTMENT_LIMIT, details)
      return { ...data }
    } catch (error: any) {
      return error.response
    }
  },

  commentOrApproveDocument: async (
    documentId: number,
    details: { [key: string]: string | number },
  ) => {
    try {
      const data: any = await axiosInstance.post(
        COMMENT_OR_APPROVE_KYC_DOCUMENT(documentId),
        details,
      )
      return data
    } catch (error: any) {
      return error.response
    }
  },

  approveKYC: async (details: { [key: string]: string | number }) => {
    try {
      const data: any = await axiosInstance.post(UPDATE_KYC, details)
      return data
    } catch (error: any) {
      return error.response
    }
  },

  // v1
  getKYC_v1: async (investorId: string) => {
    try {
      const data: any = await axiosInstance.get(GET_KYC_V1(investorId))
      return data
    } catch (error: any) {
      return error
    }
  },
  generateKYCReportLink: async (investorKycId: string) => {
    try {
      return await axiosInstance.get(GET_KYC_REPORT_LINK, {
        params: { investorKycId },
      })
    } catch (error: any) {
      return error.response
    }
  },
  getDocumentWithKYC: async (investorId: string) => {
    try {
      const data: any = await axiosInstance.get(GET_DOCUMENT_WITH_KYC(investorId))
      return data
    } catch (error: any) {
      return error
    }
  },
}
