/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react'
import './profile-detail.scss'
import { investorServices, kycServices, investmentServices, userServices } from '../../services'
import {
  InvestorProps,
  InvestorData,
  Verification,
  UserInvestmentDetails,
  KYCDetailsWithDocument,
  AccountStatus,
} from '../../lib/types'
import { RootState } from '../../store'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { showBanner } from '../../global-state/banner-slice'
import { ProfileDetailsPresenter } from './profile-details-presenter'
import { showModel } from '../../global-state/confirm-model-slice'

const ProfileDetailsContainer = () => {
  const dispatch = useDispatch()
  const { isConfirm } = useSelector((state: RootState) => state.confirmModel)
  const [investorState, setInvestor] = useState<InvestorData>()
  const [kycData, setKYCData] = useState<KYCDetailsWithDocument>()
  const { investorId } = useParams()
  const [lockStatus, setLockStatus] = useState('')
  const [investmentDetail, setInvestmentDetail] = useState<UserInvestmentDetails>()

  const fetchData = async () => {
    const investorIdParam: number = investorId ? parseInt(investorId) : 0
    try {
      const investorData = (await investorServices.getInvestorProfile(
        investorIdParam,
      )) as InvestorProps
      if (investorData)
        setInvestor({
          investor: investorData,
        })
      const response = await investmentServices.getInvestmentDetailForInvestor(investorId as string)
      if (response && response.data && response.status === 200) {
        setInvestmentDetail(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const getKYC = async () => {
      const response = await kycServices.getDocumentWithKYC(String(investorId))
      if (!!response && response.data) {
        setKYCData(response.data ?? {})
      }
    }

    getKYC()
    fetchData()
  }, [])

  useEffect(() => {
    const lockUnlockHandler = async (status: string) => {
      const data: any = (await investorServices.lockUnlockAccount({
        investorId: investorState?.investor?.userId ?? 0,
        account_status: status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
      })) as InvestorProps
      if (data) {
        setInvestor((prevState: any) => ({
          ...prevState,
          investor: {
            ...prevState.investor,
            accountStatus: status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
          },
        }))
        dispatch(
          showBanner({
            text: 'Investor account status updated',
            variant: 'success-banner',
          }),
        )
      }
    }

    if (isConfirm && investorId && lockStatus) lockUnlockHandler(lockStatus)
  }, [isConfirm])

  const handleBlockUnblock = async (status: Exclude<AccountStatus, 'DORMANT'>) => {
    status === 'ACTIVE' &&
      dispatch(
        showModel({
          name: 'Block Investor',
          message: 'Are you sure you want to block the investor?',
        }),
      )
    status === 'INACTIVE' &&
      dispatch(
        showModel({
          name: 'Investor Unlock',
          message: 'Are you sure you want to unblock the investor?',
        }),
      )
    setLockStatus(status)
  }

  const handleInvestmentLimit = async (investmentLimit: number) => {
    // converting dollar value in cents
    const investmentLimitInCents = investmentLimit * 100
    const data: any =
      investorId &&
      ((await investmentServices.setInvestmentLimit(
        parseInt(investorId),
        investmentLimitInCents,
      )) as InvestorProps)
    if (data && data.status === 200) {
      dispatch(
        showBanner({
          text: 'Investment limit updated',
          variant: 'success-banner',
        }),
      )
      fetchData()
    } else {
      dispatch(
        showBanner({
          text:
            (data.data && data.data.message) ||
            'Something went wrong while updating investment limit',
          variant: 'error-banner',
        }),
      )
    }
  }

  const handleSendVerificationEmailLink = async () => {
    const response: any =
      investorState?.investor?.email &&
      !investorState?.investor?.isEmailVerified &&
      (await userServices.sendVerificationLink(
        investorState.investor.email,
        Verification.verifyEmail,
      ))
    if (!!response && response.status === 200) {
      dispatch(
        showBanner({
          text: 'Email verification link has been sent to email successfully.',
          variant: 'success-banner',
        }),
      )
    } else {
      dispatch(
        showBanner({
          text:
            (response.data && response.data.message) || 'Something went wrong! While verify email',
          variant: 'error-banner',
        }),
      )
    }
  }

  return (
    <>
      {investorState && (
        <>
          <ProfileDetailsPresenter
            // handleFilePreview={handleFilePreview}
            // approveKYC={approveKYC}
            // saveCommentOrApprove={saveCommentOrApprove}
            // changeHandler={changeHandler}
            // handleFileUpload={handleFileUpload}
            // handleQuarterlyReport={handleQuarterlyReport}
            investorState={investorState}
            investmentDetail={investmentDetail as UserInvestmentDetails}
            kycData={kycData as KYCDetailsWithDocument}
            handleInvestmentLimit={handleInvestmentLimit}
            handleSendVerificationEmailLink={handleSendVerificationEmailLink}
            handleBlockUnblock={handleBlockUnblock}
          />
        </>
      )}
    </>
  )
}

export { ProfileDetailsContainer }
