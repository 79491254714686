import React, { useState } from 'react'
import { Popover } from 'react-tiny-popover'
import './tool-tip-component.scss'
import { ToolTipProps, ToolTipBlackProps } from '../../types'
import { CheckCircleIcon, InfoIcon, PendingIcon, ReportIcon } from '../Icon'
import { concatClassName } from '../../utils/helpers'

const ToolTip = ({ content, variant, contentClassNames, children }: ToolTipProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const showToolTip = () => {
    setIsOpen(true)
  }

  const closeToolTip = () => {
    setIsOpen(false)
  }

  const toolTipContent = (): JSX.Element => {
    const classes: string[] = contentClassNames ?? ['tool-tip-container']
    return <div className={concatClassName(classes)}>{content}</div>
  }

  if (variant === 'table-cell') {
    return (
      <td
        className={'pointer'}
        onMouseOver={() => content.length > 0 && showToolTip()}
        onMouseLeave={closeToolTip}
      >
        <Popover
          isOpen={isOpen}
          positions={['top', 'bottom', 'right', 'left']}
          content={toolTipContent}
        >
          {children}
        </Popover>
      </td>
    )
  }

  return (
    <div className={'pointer'} onMouseOver={showToolTip} onMouseLeave={closeToolTip}>
      <Popover
        isOpen={isOpen}
        positions={['top', 'bottom', 'right', 'left']}
        content={toolTipContent}
      >
        {children}
      </Popover>
    </div>
  )
}

const ToolTipBlack = ({ variant, classes, children }: ToolTipBlackProps) => {
  return (
    <div className={`${concatClassName(classes)}`}>
      <div className='tooltip'>
        {variant === 'pending-icon' && (
          <PendingIcon classes={['info-icon']} width='20px' height='24px' />
        )}

        {variant === 'info-icon' && <InfoIcon classes={['info-icon']} width='20px' height='24px' />}
        {variant === 'check-circle-icon' && <CheckCircleIcon classes={['mr-4']} height='13px' />}
        {variant === 'report-icon' && <ReportIcon classes={['mr-4']} height='13px' />}

        <span className='tooltiptext'>{children}</span>
      </div>
    </div>
  )
}

export { ToolTip, ToolTipBlack }
