import React from 'react'
import {
  Button,
  Flex,
  H2,
  Input,
  Line,
  SelectDropdown,
  Span,
  ToolTipBlack,
} from '../../lib/components'
import { orderFilters, ordersColumnDescription } from '../../lib/utils/message'
import { OrderCard } from './order-card'
import { useBreakpoint } from '../../lib/utils/hooks'
import { SM } from '../../lib/utils/constants'
import { Pagination } from '../../lib/components/Pagination'
import './order.scss'
import { Order, OrdersPresenterProps } from '../../lib/types'

const OrdersPresenter = ({
  pagination,
  handleCurrentPage,
  currentPage,
  orders,
  resetSearchParams,
  searchParams,
  handleSearchParams,
  handleInvestmentClick,
}: OrdersPresenterProps) => {
  const { breakpoint } = useBreakpoint()
  return (
    <Flex variant='column-center'>
      <H2 classes={['mb-48']} text='Orders' />
      <div className='toolbar-layout gap-12 mb-16 mt-16'>
        <Input
          name='keyword'
          value={searchParams.keyword}
          handleChange={(e) => handleSearchParams({ name: e.target.name, value: e.target.value })}
          placeholder='Search'
        />
        <SelectDropdown
          name='filterBy'
          id='filterId'
          formId=''
          list={orderFilters}
          value={searchParams.filterBy}
          placeholder={'Filter'}
          handleChange={(val) => handleSearchParams({ name: 'filterBy', value: val })}
        />
        <div className='sort-reset'>
          <Button
            handleClick={() => resetSearchParams()}
            classes={['reset-btn']}
            variant='secondary-btn'
            text='Reset'
          />
        </div>
      </div>
      <Line />
      {orders && orders.length > 0 ? (
        <>
          <div className='w-100'>
            <Flex variant='row' classes={['mb-24', 'table_scroll_horizontal']}>
              <table className={'styled-table'}>
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Opportunity Name</th>
                    <th className='table-header-with-icon'>
                      <div className='table-header-with-icon-content'>
                        Order Creation Date
                        <ToolTipBlack variant='info-icon' classes={['ml-8']}>
                          {ordersColumnDescription?.order_creation_date}
                        </ToolTipBlack>
                      </div>
                    </th>
                    <th className='table-header-with-icon'>
                      <div className='table-header-with-icon-content'>
                        Allocation Date
                        <ToolTipBlack variant='info-icon' classes={['ml-8']}>
                          {ordersColumnDescription?.allocation_date}
                        </ToolTipBlack>
                      </div>
                    </th>
                    <th>Investor Portfolio ID</th>
                    <th>Investor Name</th>
                    <th>Investor Email</th>
                    <th>KYC</th>
                    <th>Welcome Letter</th>
                    <th>Client Agreement</th>
                    <th>Investment Amount</th>
                    <th>Total amount to be transferred</th>
                    <th>SWIFT Copy / Transaction Ref. Number</th>
                    <th>Investment Completed</th>
                    <th>Investment Details</th>
                  </tr>
                </thead>
                <tbody>
                  {orders &&
                    orders.length > 0 &&
                    orders.map(
                      (
                        {
                          order_id,
                          opportunity_title,
                          order_creation_date,
                          investor_portfolio_id,
                          investor_name,
                          investor_email,
                          investment_amount,
                          total_amount_to_be_transferred,
                          investment_completed,
                          opportunity_slug,
                          opportunity_id,
                          investor_id,
                          investment_id,
                          completed_steps,
                          unit_allocation_date,
                          investor_account_status,
                          welcome_letter_link,
                          agreement_document_id,
                          investor_kyc_id,
                          payment_copy,
                          transaction_reference_number,
                        }: Order,
                        idx: number,
                      ) => (
                        <OrderCard
                          key={order_id + idx}
                          order_id={order_id}
                          opportunity_title={opportunity_title}
                          order_creation_date={order_creation_date}
                          unit_allocation_date={unit_allocation_date}
                          investor_portfolio_id={investor_portfolio_id}
                          investor_name={investor_name}
                          investor_email={investor_email}
                          investment_amount={investment_amount}
                          total_amount_to_be_transferred={total_amount_to_be_transferred}
                          investment_completed={investment_completed}
                          completed_steps={completed_steps}
                          investor_account_status={investor_account_status}
                          welcome_letter_link={welcome_letter_link}
                          agreement_document_id={agreement_document_id}
                          investor_kyc_id={investor_kyc_id}
                          payment_copy={payment_copy}
                          transaction_reference_number={transaction_reference_number}
                          handleInvestmentClick={() =>
                            handleInvestmentClick(
                              opportunity_slug,
                              opportunity_id,
                              investor_id,
                              investment_id,
                            )
                          }
                        />
                      ),
                    )}
                </tbody>
              </table>
            </Flex>
          </div>
          {breakpoint && breakpoint === SM ? null : (
            <Pagination
              currentPage={currentPage}
              handleCurrentPage={handleCurrentPage}
              pagination={pagination}
            />
          )}
        </>
      ) : (
        <Flex variant={'column-center'} classes={['mt-12']}>
          <Span classes={['center']} variant={'thin'} text='No Orders Found' />
        </Flex>
      )}
    </Flex>
  )
}

export { OrdersPresenter }
