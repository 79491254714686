import React, { useState } from 'react'
import {
  displayDollar,
  getFileKeyFromSignedUrl,
  getFormattedDate,
  getTableClassNames,
  getToolTipText,
} from '../../lib/utils/helpers'
import {
  EyeIcon,
  ToolTip,
  ToolTipBlack,
  ExternalLinkIcon,
  DownloadIcon,
} from '../../lib/components'
import './order.scss'
import { stuckAtInvestmentMessage } from '../../lib/utils/message'
import { OrderCardProps } from '../../lib/types'
import { documentServices, kycServices, projectServices } from '../../services'
import { CircularProgress } from '@mui/material'
import { useDispatch } from 'react-redux'
import { showBanner } from '../../global-state/banner-slice'

function OrderCard({
  order_id,
  opportunity_title,
  order_creation_date,
  investor_portfolio_id,
  investor_name,
  investor_email,
  currency_code,
  investment_amount,
  total_amount_to_be_transferred,
  investment_completed,
  completed_steps,
  handleInvestmentClick,
  unit_allocation_date,
  investor_account_status,
  welcome_letter_link,
  agreement_document_id,
  investor_kyc_id,
  payment_copy,
  transaction_reference_number,
}: OrderCardProps) {
  const dispatch = useDispatch()
  const [isAgreementLoading, setIsAgreementLoading] = useState(false)
  const [isKYCReportLoading, setIsKYCReportLoading] = useState(false)

  const formattedDate = (date: string) => getFormattedDate(new Date(date), 'D MMM YYYY [at] h:mm A')

  const getDocumentDownloadLink = async (documentId: string) => {
    const response = await documentServices.getDocumentDownloadLink(documentId)

    if (response.status === 200 && response.data) {
      return response.data
    }
  }

  const getKYCReportLink = async (investorKycId: string) => {
    const response = await kycServices.generateKYCReportLink(investorKycId)

    if (response.status === 200 && response.data) {
      return response.data
    }
  }

  const handleWelcomeLetterBtnClick = () => {
    window.open(welcome_letter_link)
  }

  const handleDownloadAgreementBtnClick = async () => {
    try {
      setIsAgreementLoading(true)
      const downloadLink = await getDocumentDownloadLink(agreement_document_id)
      setIsAgreementLoading(false)
      window.open(downloadLink)
    } catch (error) {
      console.error(error)
    }
  }

  const handleGenerateKYCReportBtnClick = async () => {
    try {
      setIsKYCReportLoading(true)
      const { reportLink } = await getKYCReportLink(investor_kyc_id)
      setIsKYCReportLoading(false)
      window.open(reportLink)
    } catch (error) {
      console.error(error)
    }
  }

  const handleFilePreview = async (fileKey: string) => {
    fileKey = (fileKey as any).replaceAll('/', '%2F')

    const signedUrl = getFileKeyFromSignedUrl(fileKey)
    const data = await projectServices.previewFile(signedUrl)
    if (data && data.data && data.status === 200) return data.data.url
    else {
      dispatch(
        showBanner({
          text: (data.data && data.data.message) || 'Something went wrong preview document',
          variant: 'error-banner',
        }),
      )
      return ''
    }
  }

  const handlePaymentCopyClick = async () => {
    const url = await handleFilePreview(payment_copy)
    url && window.open(url, '_blank')
  }

  const getText = () => {
    return getToolTipText({
      investor_account_status,
      investment_completed,
      completed_steps,
      payment_copy,
      transaction_reference_number,
    })
  }
  return (
    <tr
      key={order_id}
      className={getTableClassNames({
        investor_account_status,
        investment_completed,
        completed_steps,
        payment_copy,
        transaction_reference_number,
      })}
    >
      <ToolTip variant='table-cell' content={getText()}>
        <span>{order_id}</span>
      </ToolTip>

      <ToolTip variant='table-cell' content={getText()}>
        <span>{opportunity_title}</span>
      </ToolTip>

      <ToolTip variant='table-cell' content={getText()}>
        <span>{order_creation_date ? formattedDate(order_creation_date) : 'Not available'}</span>
      </ToolTip>

      <ToolTip variant='table-cell' content={getText()}>
        <span>{unit_allocation_date ? formattedDate(unit_allocation_date) : 'Not available'}</span>
      </ToolTip>
      <ToolTip variant='table-cell' content={getText()}>
        <span>{investor_portfolio_id}</span>
      </ToolTip>

      <ToolTip variant='table-cell' content={getText()}>
        <span>{investor_name}</span>
      </ToolTip>

      <ToolTip variant='table-cell' content={getText()}>
        <span>{investor_email}</span>
      </ToolTip>

      <td>
        {!isKYCReportLoading ? (
          <ExternalLinkIcon classes={['pointer']} onClick={handleGenerateKYCReportBtnClick} />
        ) : (
          <CircularProgress size={20} />
        )}
      </td>

      <td>
        {welcome_letter_link ? (
          <DownloadIcon classes={['pointer']} onClick={handleWelcomeLetterBtnClick} />
        ) : (
          'N.A.'
        )}
      </td>

      <td>
        {!isAgreementLoading ? (
          <DownloadIcon classes={['pointer']} onClick={handleDownloadAgreementBtnClick} />
        ) : (
          <CircularProgress size={20} />
        )}
      </td>

      <ToolTip variant='table-cell' content={getText()}>
        <span>{displayDollar(investment_amount.toString(), currency_code)}</span>
      </ToolTip>

      <ToolTip variant='table-cell' content={getText()}>
        <span>{displayDollar(total_amount_to_be_transferred.toString(), currency_code)}</span>
      </ToolTip>

      <td>
        {payment_copy && (
          <EyeIcon
            classes={['align-center', 'pointer']}
            height='24px'
            width='24px'
            onClick={handlePaymentCopyClick}
          />
        )}

        {!payment_copy && transaction_reference_number && <>{transaction_reference_number}</>}
        {!payment_copy && !transaction_reference_number && <>N.A.</>}
      </td>

      <td>
        <div className={'table-cell-with-icon'}>
          {investment_completed ? 'Yes' : 'No'}
          {!investment_completed && (
            <ToolTipBlack variant='pending-icon' classes={['ml-8']}>
              {stuckAtInvestmentMessage[Number(completed_steps) + 1]?.stepDescription}
            </ToolTipBlack>
          )}
        </div>
      </td>
      <td>
        <EyeIcon
          classes={['align-center', 'pointer']}
          height='24px'
          width='24px'
          onClick={() => handleInvestmentClick()}
        />
      </td>
    </tr>
  )
}
export { OrderCard }
